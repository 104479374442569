import React, { useContext } from "react";

import { ShopContext } from "../../context";

import {
  SwipeableList,
  SwipeableListItem,
} from "@sandstreamdev/react-swipeable-list";

import CartItem from "../CartItem/CartItem";

import { FiTrash2, FiPlus } from "react-icons/fi";
import { FaShoppingBasket } from "react-icons/fa";

type Props = {
  cartItems?: CartItemType[];
  addToCart: (clickedItem: CartItemType) => void;
  removeFromCart: (clickedItem: CartItemType) => void;
  showProductsInCart?: boolean;
  isTutorialPlayed: boolean;
};

const Cart: React.FC<Props> = ({
  cartItems,
  addToCart,
  removeFromCart, 
  showProductsInCart,
}) => {
  const { shop, shopMessages } = useContext(ShopContext);

  const calculateTotal = (items?: CartItemType[]) => {
    if (items)
      return items
        .reduce((ack: number, item) => ack + item.amount * item.priceToDisplay, 0)
        .toFixed(2);

    const initialPrice = 0;

    return initialPrice.toFixed(2);
  };

  const shopInfo = JSON.parse(localStorage.getItem("shopInfo")!);

  const renderCartItems = () => (
    <SwipeableList>
      {cartItems?.map((item) => (
        <SwipeableListItem
          key={item.barcode}
          swipeLeft={{
            content: (
              <div className="swipeLeftContent">
                <div className="swipe_text">{shopMessages?.SHOP_PAGE?.DELETE_ITEM}</div>
                <div className="swipe_icon">
                  <FiTrash2 size="24" />
                </div>
              </div>
            ),
            action: () => removeFromCart(item),
          }}
          swipeRight={
            item?.productId?.length !== 5
              ? {
                  content: (
                    <div className="swipeRightContent">
                      <div className="swipe_icon">
                        <FiPlus
                          size="30"
                          color={`${shop?.shopCustomCss?.white}`}
                        />
                      </div>
                      <div className="swipe_text">
                        {shopMessages?.SHOP_PAGE?.ADD_ITEM_AGAIN}
                      </div>
                    </div>
                  ),
                  action: () => addToCart(item),
                }
              : undefined
          }
        >
          <CartItem key={item.barcode} item={item} />
        </SwipeableListItem>
      ))}
    </SwipeableList>
  );

  return (
    <div className="cart">
      <div className="cart_items">
        {cartItems?.length === 0 && (
          <p className="cart__no_items">No items in cart.</p>
        )}
        {showProductsInCart && (
          <div className="cart__render">{renderCartItems()}</div>
        )}
      </div>
      <div className="cart__total" id="total">
        <div className="cart__basket">
          <FaShoppingBasket size="24" color={`${shop?.shopCustomCss?.white}`} />
          <p>Total</p>
        </div>
        <p>{shopInfo.currency} {calculateTotal(cartItems)}</p>
      </div>
    </div>
  );
};

export default Cart;
