import React, { FC, useContext } from "react";
import { useTranslation } from "react-i18next";

import { Button } from "../..";

import { PopUpTemplate } from "../../../templates";

import close from "../../../assets/icons/white_close_icon.png";
import { ErrorContext, ShopContext } from "../../../context";

const ErrorPopup: FC<{}> = () => {
  const { shopMessages } = useContext(ShopContext);
  const { error, setError, showErrorPopup, setShowErrorPopup } =
    useContext(ErrorContext); 

  const { t } = useTranslation();

  const closePopup = () => {
    setShowErrorPopup(false);
    setError(undefined);
  };

  const badRequestError = error?.status === 400;

  const authenticationError = error?.status === 401;

  const notFoundError = error?.status === 404;

  const paymentError = error?.status === 442;

  const serverError = error?.status === 500;

  const buttonText = shopMessages?.POPUPS?.ERROR_POPUP?.BUTTON_TEXT ?? t("POPUPS.ERROR_POPUP.BUTTON_TEXT")

  return (
    <React.Fragment>
      <PopUpTemplate showPopup={showErrorPopup}>
        <div className="error_popup">
          <div className="error_popup__content">
            <div className="error_popup__head">
              <img
                src={close}
                alt="Close modal"
                className="error_popup__close"
                onClick={closePopup}
              />
              <p className="error_popup__title">
                {t("POPUPS.ERROR_POPUP.TITLE")}
              </p>
              <p className="error_popup__subtitle">
                {t("POPUPS.ERROR_POPUP.SUBTITLE")}
              </p>
              {notFoundError && (
                  <div className="error_popup__text">
                    {t("POPUPS.ERROR_POPUP.NOT_FOUND_ERROR")}
                  </div>
              )}
              {authenticationError && (
                  <div className="error_popup__text">
                    {t("POPUPS.ERROR_POPUP.CONNECTION_TO_THE_SERVER_FAILED")}
                  </div>
              )}
              {badRequestError && (
                  <div className="error_popup__text">
                    {t("POPUPS.ERROR_POPUP.BAD_REQUEST_ERROR")}
                  </div>
              )}
              {serverError && (
                <p className="error_popup__text">
                  {t("POPUPS.ERROR_POPUP.ERROR_500_TEXT")}
                </p>
              )}
              {paymentError && (
                <p className="error_popup__text">
                  {t("POPUPS.ERROR_POPUP.PAYMENT_ERROR")}
                </p>
              )}
            </div>
            <div className="error_popup__button">
              <Button
                type="white"
                clickHandler={closePopup}
                text={buttonText}
              />
            </div>
          </div>
        </div>
      </PopUpTemplate>
    </React.Fragment>
  );
};

export default ErrorPopup;
