import React, { createContext, useContext } from "react";

import { UserContext } from "./UserContext";

import LocalStorageService from "../services/LocalStorageService";
import logEvents from "../services/logEvents";
import {DebugMessageId} from "./types";

interface IBarcodeContext {
  handleAddToCart(scannedItem: CartItemType): void;
  handleRemoveFromCart(clickedItem: CartItemType): void;
}

const BarcodeContext = createContext({} as IBarcodeContext);

const { save } = LocalStorageService;
interface IBarcodeContextProps {
  children: React.ReactNode;
}

const BarcodeContextProvider: React.FC<IBarcodeContextProps> = (props) => {
  const { guestUser, setGuestUser } = useContext(UserContext);

  const handleAddToCart = (clickedItem: CartItemType) => {
    const cartItems = guestUser.shoppingCart;

    const isItemInCart = cartItems.find(
      (item) => item.barcode === clickedItem.barcode
    );

    if (isItemInCart) {
      cartItems.forEach((item, index) => {
        if (item.barcode === clickedItem.barcode) {
          cartItems[index].amount = cartItems[index].amount + 1;
        }
      });
      logEvents.logEvent(DebugMessageId.article_count_increased, `Count for article ${clickedItem.title}, ${clickedItem.productId}, ${clickedItem.barcode} increased`, "DEBUG");
    } else {
      clickedItem.amount = 1;
      cartItems.push(clickedItem);
      logEvents.logEvent(DebugMessageId.article_added_to_based, `Article added to basket ${clickedItem.title}, ${clickedItem.subtitle}, ${clickedItem.price}, ${clickedItem.productId}, ${clickedItem.barcode}`, "DEBUG");
    }

    save({
      ...guestUser,
      shoppingCart: cartItems,
    });

    setGuestUser({
      ...guestUser,
      shoppingCart: cartItems,
    });
  };

  const handleRemoveFromCart = (clickedItem: CartItemType) => {
    const cartItems: CartItemType[] = guestUser.shoppingCart;

    const itemIndex = cartItems.findIndex(
      (item: CartItemType) =>
        item.barcode === clickedItem.barcode
    );

    if (cartItems[itemIndex].amount > 1) {
      cartItems[itemIndex].amount = cartItems[itemIndex].amount - 1;
      logEvents.logEvent(DebugMessageId.article_count_decreased, `Count for article ${clickedItem.title}, ${clickedItem.productId}, ${clickedItem.barcode} decreased`, "DEBUG");
    } else {
      cartItems.splice(itemIndex, 1);
      logEvents.logEvent(DebugMessageId.article_removed_from_cart, `Article ${clickedItem.title}, ${clickedItem.productId}, ${clickedItem.barcode} removed from cart`, "DEBUG");
    }

    save({
      ...guestUser,
      shoppingCart: cartItems,
    });

    setGuestUser({
      ...guestUser,
      shoppingCart: cartItems,
    });
  };

  const providerValue = {
    handleAddToCart,
    handleRemoveFromCart,
  };

  return (
    <BarcodeContext.Provider value={providerValue}>
      {props.children}
    </BarcodeContext.Provider>
  );
};

export { BarcodeContextProvider, BarcodeContext };
