import React, { FC, useContext } from "react";

import { AxiosContext } from "../../context";

import animationData from "./loader.json";
import LottieLoader from "react-lottie-loader";

const Loader: FC<{}> = () => {
  const { displayLoader } = useContext(AxiosContext);

  return (
    <React.Fragment>
      {displayLoader && (
        <div className="loader">
          <LottieLoader
            animationData={animationData}
            autoplay={displayLoader}
            className="loader__lottie"
          />
        </div>
      )}
    </React.Fragment>
  );
};

export default Loader;
