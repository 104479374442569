export interface PaymentData {
    items: Item[] | undefined;
    successUrl: string;
    failedUrl: string;
    language: string;
    shopId: number;
    token?: { id: string | null },
}

export interface CreatePaymentTokenData {
    shopId: string;
    transactionId: string;
}

export interface PaymentTokenDetails {
    shopId: string;
    paymentToken: string;
}

export interface CreateTokenizationUrlData {
    language: string;
    shopId: string;
    successUrl: string;
    failedUrl: string;
}

export interface RegisterTokenData {
    shopId: string;
    spaceId: string;
    transactionId: string;
    paymentToken: string;
}

export interface CheckoutData {
    transactionId: string,
    shopId: string,
    status: string,
}

export interface IErrorMessage {
    errorDescription: string;
    errorMsgColor: string;
    errorSubtitle: string;
    errorTitle: string;
}
// error messages is returned as an object with id as a index from getShopData
export interface IErrorMessages {
    1: IErrorMessage;
    4: IErrorMessage;
    5: IErrorMessage;
    6: IErrorMessage;
    11: IErrorMessage;
    12: IErrorMessage;
    13: IErrorMessage;
    14: IErrorMessage;
    15: IErrorMessage;
    18: IErrorMessage;
    19: IErrorMessage;
    20: IErrorMessage;
    21: IErrorMessage;
}


export enum ErrorMessagesId {
    'out_of_proximity' = 1,
    'no_match_for_scanned_product' = 4,
    'no_access_to_camera' = 5,
    'no_access_to_location' = 6,
    'authentication_issue' = 7,
    'bad_request_issue' = 8,
    'server_issue' = 9,
    'shop_id_can_not_be_found' = 10,
    'connectivity_issue' = 11,
    'scan_cannot_be_used' = 12,
    'psp_authentication_issue'= 13,
    'psp_not_reachable' = 14,
    'any_payment_issue' = 15,
    'payment_error' = 18,
    'shop_is_closed' = 19
}

export enum DebugMessageId {
    't_and_c_accepted' = 23,
    'language_switch' = 30,
    'article_scanned' = 31,
    'special_barcode' = 32,
    'article_added_to_based' = 33,
    'article_count_increased' = 35,
    'article_count_decreased' = 36,
    'article_removed_from_cart' = 37,
    'checkout_initialed' = 40,
    'not_all_article_scanned' = 41,
    'purchase_order_sent_to_backend' = 45,
    'show_receipt' = 46
}

export enum InfoMessageId {
    'unsupported_language_opened_the_shop' = 22,
    'payment_token_initialed' = 24,
    'payment_token_success' = 25,
    'payment_token_failed' = 26,
    'open_door_triggered' = 27,
    'already_in_shop' = 28,
    'shop_opened' = 29,
    'leave_shop_from_product_scan' = 38,
    'emergency_exit' = 39,
    'payment_initialed' = 42,
    'payment_success' = 43,
    'payment_failed' = 44,
    'open_door_exit' = 47,
    'shop_again' = 48,
    'fallback_page_opened' = 49,
    'open_door_triggered_shop_full' = 50
}

export enum WarningMessageId {
    'article_not_found' = 34
}