import React, { FC, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Switch from "react-switch";

import { AxiosContext, ShopContext } from "../../context";

import Button from "../Button/Button";
import config from "../../config/config";

import close from "../../assets/icons/green_close_icon.png";
import check from "../../assets/icons/green_check_circle_icon.png";
import { CustomErrorPopup, GeneralPopup } from "..";
import { DebugMessageId, InfoMessageId, PaymentData, PaymentTokenDetails } from "../../context/types";

import LogEvents from "../../services/logEvents";

interface ConfirmProps {
  setShowProductsInCart(data: boolean): void;
  setShowConfirm(data: boolean): void;
  setShowScanner(data: boolean): void;
  setShowPayButton(data: boolean): void;
  setShowTotal(data: boolean): void;
  cartItems?: CartItemType[];
}

const Confirm: FC<ConfirmProps> = (props) => {
  const { setShowConfirm, setShowScanner, setShowPayButton, cartItems } = props;

  const shopInfo = JSON.parse(localStorage.getItem("shopInfo")!);

  const [showTermsAndConditionsSwitch, setShowTermsAndConditionsSwitch] = useState<boolean>();

  const {
    shop,
    pay,
    setTermsCloseButton,
    showTermsPopup,
    setShowTermsPopup,
    shopData,
    shopMessages
  } = useContext(ShopContext);

  const { setDisplayLoader, setPaymentLinkClicked } = useContext(AxiosContext);

  const { t } = useTranslation();

  const [showCustomErrorPopup, setShowCustomErrorPopup] = useState(false);
  const [termsAndConditionsAccepted, setTermsAndConditionsAccepted] = useState(false);

  const [token, setToken] = useState<string>();

  function isOnline() {
    return navigator.onLine;
  }

  const tAndCLinkHandler = () => {
    setTermsCloseButton(true);
    setShowTermsPopup(true);
  };

  const AGBsButtonClickHandler = () => {
    setShowTermsPopup(false);
    setTermsCloseButton(false);
  };

  const renderCartItems = () => {
    return cartItems?.map((item) => ({
      pricePerUnit: Number(item.price),
      uniqueId: item.productId,
      barcode: item.barcode,
      amountIncludingTax:
        Math.round((item.priceToDisplay * item.amount + Number.EPSILON) * 100) / 100,
      name: item.title,
      quantity: item.amount,
      ... (item.weight) && { weight: Number(item.weight) },
      type: "PRODUCT"
    }));
  };

  const getPaymentData = (): PaymentData => {
    const items = renderCartItems();
    const shopDataLocal = JSON.parse(localStorage.getItem("shopData")!);
    return {
      items,
      successUrl: config.paymentSuccessUrls,
      failedUrl: config.paymentFailureUrls,
      language: shopDataLocal.currentLanguage,
      shopId: shopDataLocal.shopId,
    };
  };

  const continueWithPayment = () => {
    if (isOnline()) {
      LogEvents.logEvent(InfoMessageId.payment_initialed, "Payment initiated, payment PSP: Wallee");
      LogEvents.logEvent(DebugMessageId.checkout_initialed, "Checkout initiated", "DEBUG");
      // LogEvents.logEvents("paymentInitiated", "");
      setPaymentLinkClicked(true);
      let paymentData = getPaymentData();

      if (token) {
        paymentData = {
          ...paymentData,
          token: {
            id: token,
          },
        };
      }

      setDisplayLoader(true);
      return pay(paymentData)
        .then(() => {
          setDisplayLoader(true);
        })
    } else {
      setShowCustomErrorPopup(true);
    }
  };

  const changePayment = () => {
    if (!termsAndConditionsAccepted) return;

    if (isOnline()) {
      LogEvents.logEvent(DebugMessageId.checkout_initialed, "Checkout initialed", "DEBUG");
      setPaymentLinkClicked(true);
      setDisplayLoader(true);
      return pay(getPaymentData())
        .then(() => {
          setDisplayLoader(true);
        })
    } else {
      setShowCustomErrorPopup(true);
    }
  };

  const noButtonClickHandler = () => {
    LogEvents.logEvent(DebugMessageId.not_all_article_scanned, "Not all product scanned clicked", "DEBUG");
    setShowScanner(true);
    setShowConfirm(false);
    setShowPayButton(true);
  };

  useEffect(() => {
    let paymentTokenDetailsList: PaymentTokenDetails[] = JSON.parse(localStorage.getItem("paymentTokenDetailsList") || '[]');
    const shopData = JSON.parse(localStorage.getItem('shopData')!);

    const tokenDetails = paymentTokenDetailsList.filter(tokenDetails => tokenDetails.shopId === shopData.shopId);
    const token = tokenDetails[0]?.paymentToken;
    setToken(token?.toString());
  }, [])


  useEffect(() => {
    if (shopInfo.termsAndConditionsAccess === "ACCESS" || shopInfo.termsAndConditionsAccess === "NONE") {
      setShowTermsAndConditionsSwitch(false);
      setTermsAndConditionsAccepted(true);
      return;
    }
    setShowTermsAndConditionsSwitch(true);
  }, [])

  return (
    <React.Fragment>
      <GeneralPopup
        setShowPopup={setShowTermsPopup}
        showPopup={showTermsPopup}
        type="terms"
        clickHandler={AGBsButtonClickHandler}
        onClosePopup={AGBsButtonClickHandler}
      />
      <CustomErrorPopup
        showPopup={showCustomErrorPopup}
        setShowPopup={setShowCustomErrorPopup}
        errorMessage={t("POPUPS.ERROR_POPUP.NO_INTERNET")}
      />
      <div className="confirm">
        <div className="confirm__content">
          {shop.confirmScannedProducts && (
            <p className="confirm__title">{shop.confirmScannedProductsText}</p>
          )}
          <div className="confirm__close_button">
            <Button
              type="white"
              rightIcon={close}
              clickHandler={noButtonClickHandler}
              text={shopMessages?.SHOP_PAGE?.KEEP_SHOPPING}
            />
          </div>
          {showTermsAndConditionsSwitch &&
            <div className="confirm__switch_container">
              <Switch
                onChange={() => setTermsAndConditionsAccepted(!termsAndConditionsAccepted)}
                checked={termsAndConditionsAccepted}
                onColor="#fff"
                offColor="#5A5A5A"
                handleDiameter={17}
                uncheckedIcon={false}
                checkedIcon={false}
                onHandleColor="#5A5A5A"
                offHandleColor="#FFF"
                height={25}
                width={45}
                borderRadius={20}
              />
              <div className="confirm__switch_text_container">
                {shopData.currentLanguage === "de" && (
                  <p>
                    {shopMessages?.SHOP_PAGE?.SWITCH_TEXT_1}{" "}
                    <span
                      className="confirm__t_and_c_link"
                      onClick={tAndCLinkHandler}
                    >
                      {shopMessages?.SHOP_PAGE?.T_C}
                    </span>{" "}
                    {shopMessages?.SHOP_PAGE?.SWITCH_TEXT_2}
                  </p>
                )}
                {shopData.currentLanguage !== "de" && (
                  <p>
                    {shopMessages?.SHOP_PAGE?.SWITCH_TEXT_1}{" "}
                    <span
                      className="confirm__t_and_c_link"
                      onClick={tAndCLinkHandler}
                    >
                      {shopMessages?.SHOP_PAGE?.T_C}
                    </span>
                  </p>
                )}
              </div>
            </div>
          }
          <div className="confirm__button">
            <Button
              type="white"
              rightIcon={check}
              clickHandler={continueWithPayment}
              disabled={!termsAndConditionsAccepted}
              text={shopMessages?.SHOP_PAGE?.YES_WANT_TO_PAY}
            />
            {token && (
              <p
                className={
                  !termsAndConditionsAccepted ? "disabled changePayment" : "changePayment"
                }
                onClick={changePayment}
              >
                {shopMessages?.SHOP_PAGE?.SECONDARY_PAYMENT_LINK}
              </p>
            )}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Confirm;
