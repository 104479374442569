import React, { FC, useContext } from "react";
import { useHistory } from "react-router-dom";

import { ShopContext } from "../../../context";

import { PopUpTemplate } from "../../../templates";
import { Button } from "../../../components";

import greenCheckCircle from "../../../assets/icons/green_check_circle_icon.png";
import exit from "../../../assets/icons/green_exit_icon.png";
import close from "../../../assets/icons/white_close_icon.png";
interface QuestionPopupProps {
  setShowPopup(data: boolean): void;
  showPopup: boolean;
}

const QuestionPopup: FC<QuestionPopupProps> = (props) => {
  const { setShowPopup, showPopup } = props;

  const {shop, shopMessages} = useContext(ShopContext);

  const history = useHistory();

  const payButtonClickHandler = () => {
    setShowPopup(false);
  };

  const exitButtonClickHandler = () => { 
    history.push("/exit");
  };


  return (
    <React.Fragment>
      <PopUpTemplate showPopup={showPopup}>
        <div className="question_popup">
          <div className="question_popup__content">
            <img
              src={close}
              alt="Close modal"
              className="question_popup__close"
              onClick={() => setShowPopup(false)}
            />
            <p className="question_popup__title">{shop.shopLeavingTitle}</p>
            <p className="question_popup__subtitle">{shop.shopLeavingSubtitle}</p>
            <div className="question_popup__button">
              <Button
                type="white"
                rightIcon={exit}
                clickHandler={exitButtonClickHandler}
                text={shopMessages?.POPUPS?.QUESTION_POPUP?.YES_EXIT_SHOP}
              />
            </div>
            <div className="question_popup__button">
              <Button
                type="white"
                rightIcon={greenCheckCircle}
                clickHandler={payButtonClickHandler}
                text={shopMessages?.POPUPS?.QUESTION_POPUP?.WANT_TO_PAY}
              />
            </div>
          </div>
        </div>
      </PopUpTemplate>
    </React.Fragment>
  );
};

export default QuestionPopup;
