class Store {
    state: {
       shop: any,
       outOfProximityError: string
    };
    constructor() {
      this.state = {
        shop: null,
        outOfProximityError: ''
      }
    }
  }
  
  export default new Store();
