import React, {FC, useContext, useEffect, useState} from "react";

import { Button } from "../..";

import { PopUpTemplate } from "../../../templates";

import close from "../../../assets/icons/white_close_icon.png";
import { NewErrorContext, ShopContext } from "../../../context";
import {ErrorMessagesId, IErrorMessage} from "../../../context/types";
import LogEvents from "../../../services/logEvents";
import {useHistory} from "react-router-dom";

const NewErrorPopup: FC<{}> = () => {
  const redColor = "#ec1b24";
  const history = useHistory();

  const { shopMessages, shopErrorMessages } = useContext(ShopContext);
  const { errorId, setErrorId, errorMessageForLog, setErrorMessageForLog } = useContext(NewErrorContext);
  const [error, setError] = useState<IErrorMessage|null>(null);
  const [backgroundColor, setBackgroundColor] = useState(redColor);

  const closeErrorPopup = () => {
    if (errorId === ErrorMessagesId.shop_is_closed) {
      setErrorId(null);
      history.push('/closed');
    }
    setErrorId(null);
  }

  useEffect(() => {
    if (errorId && String(errorId) in shopErrorMessages) {
      // @ts-ignore
      const foundedError = shopErrorMessages[errorId];
      setError(foundedError);
      if (foundedError.errorMsgColor) {
        setBackgroundColor(foundedError.errorMsgColor);
      }

      const errorMessage = errorMessageForLog ?? foundedError.errorDescription;
      LogEvents.logEvent(errorId, errorMessage, "ERROR");
      return;
    }
    setError(null);
    setErrorMessageForLog(null);
    setBackgroundColor(redColor);
  }, [errorId])

  return (
    <React.Fragment>
      <PopUpTemplate showPopup={error !== null}>
        <div className="error_popup">
          <div className="error_popup__content" style={{backgroundColor: backgroundColor}}>
            <div className="error_popup__head">
              <img
                src={close}
                alt="Close modal"
                className="error_popup__close"
                onClick={closeErrorPopup}
              />
              <p className="error_popup__title">
                {error?.errorTitle}
              </p>
              <p className="error_popup__subtitle">
                {error?.errorSubtitle}
              </p>
              <p className="error_popup__text">
                {error?.errorDescription}
              </p>
            </div>
            <div className="error_popup__button">
              <Button
                type="white"
                clickHandler={closeErrorPopup}
                text={shopMessages?.POPUPS?.ERROR_POPUP?.BUTTON_TEXT}
              />
            </div>
          </div>
        </div>
      </PopUpTemplate>
    </React.Fragment>
  );
};

export default NewErrorPopup;
