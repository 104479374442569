import i18n from 'i18next';
import { initReactI18next } from "react-i18next";
import { en, de, it, fr } from './locales';

const resources = {
    EN:{
        translation: en
    },
    DE:{
        translation: de
    },
    IT:{
        translation: it
    },
    FR:{
        translation: fr
    }
}

i18n.use(initReactI18next)
    .init({
        resources,
        lng: 'EN',
        interpolation:{
            escapeValue: false
        },
        react: {
            bindI18n: 'languageChange',
            bindI18nStore: 'add'
        }
    })
    
export default i18n;