import React, { FC, createContext, useState } from "react";
interface IErrorContext {
  setError(data: any): void;
  error: any;
  showErrorPopup: boolean;
  setShowErrorPopup(data: boolean): void;
}

const ErrorContext = createContext({} as IErrorContext);

interface ErrorContextProps {
  children: React.ReactNode;
}

const ErrorContextProvider: FC<ErrorContextProps> = (props) => {
  const [error, setError] = useState();
  const [showErrorPopup, setShowErrorPopup] = useState(false);

  const providerValue = {
    error,
    setError,
    showErrorPopup,
    setShowErrorPopup,
  };

  return (
    <ErrorContext.Provider value={providerValue}>
      {props.children}
    </ErrorContext.Provider>
  );
};

export { ErrorContext, ErrorContextProvider };
