import React, { useContext, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

import { NewErrorContext, ShopContext } from "../../context";

import ExpandableButton from "../ExpandableButton/ExpandableButton";
import Button from "../Button/Button";
import UnlockAnimation from "../UnlockAnimation/UnlockAnimation";
import CustomErrorPopup from "../popups/CustomErrorPopup/CustomErrorPopup";
import { GeneralPopup } from "..";

import CheckCircleIcon from "../../assets/icons/white_check_circle_icon.png";

import Store from "../../store/store";
import { ErrorMessagesId } from "../../context/types";
import LogEvents from "../../services/logEvents";
import whiteEnvelope from "../../assets/icons/mail_white.png";
import { useTranslation } from "react-i18next";

const UnlockStore: React.FC<{}> = () => {
  const {
    shop,
    letUserInShop,
    canUserEnterTheShop,
    getGeolocation,
    openDoorData,
    setTermsCloseButton,
    setLogAlreadyInStore,
    shopMessages,
    shopErrorMessages,
    shopData
  } = useContext(ShopContext);
  const { setErrorId } = useContext(NewErrorContext);

  const { t, i18n } = useTranslation();

  const [showRedCircle, setShowRedCircle] = useState(false);
  const [isButtonClicked, setIsButtonClicked] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [showCustomErrorPopup, setShowCustomErrorPopup] = useState(false);

  const history = useHistory();

  const successCallback = () => {
    if (canUserEnterTheShop()) {
      setLogAlreadyInStore(true);
      letUserInShop();
      history.push("/shop");
      return;
    }
    setShowRedCircle(true);
    setTimeout(() => {
      history.go(0);
      return;
    }, shop.doorWaitTimeInMs);
  };

  const handleAlreadyInStore = () => {
    if (shop?.locationCheckRequired && !canUserEnterTheShop()) {
      LogEvents.logEvent(ErrorMessagesId.out_of_proximity, "User is not in shop proximity", "ERROR");
      localStorage.removeItem("inStore");
      setIsButtonClicked(true);
      setShowRedCircle(true);
      return;
    }

    setIsButtonClicked(true);
    successCallback();
    letUserInShop();
  }

  const alreadyInStoreButtonClickHandler = () => {
    if (!shop?.locationCheckRequired) {
      handleAlreadyInStore();
      return
    }

    getGeolocation(() => {
      handleAlreadyInStore();
    }, () => {
      setErrorId(ErrorMessagesId.no_access_to_location);
    })
  };

  const tAndCLinkHandler = () => {
    setTermsCloseButton(true);
    setShowPopup(true);
  };

  const outOfProximity = Store.state.outOfProximityError;

  const isOutOfProximity = () => {
    if (shop?.locationCheckRequired && !canUserEnterTheShop()) {
      return true;
    }
    return false;
  };

  const renderErrorMessage = () => {
    if (
      (openDoorData.status === "CLOSED" ||
        openDoorData.status === "MAINTENANCE") &&
      showRedCircle
    ) {
      const errorMessage = shopErrorMessages?.[ErrorMessagesId.shop_is_closed];
      return (
        <div className="unlock_store__bottom">
          <p className="unlock_store__alert_text">{errorMessage?.errorDescription}</p>
        </div>
      );
    }
    if (openDoorData.status === "FULL") {
      return (
        <div className="unlock_store__bottom">
          <p className="unlock_store__alert_text">{openDoorData.text}</p>
        </div>
      );
    }
    if (!showRedCircle && canUserEnterTheShop()) {
      return (
        <div className="unlock_store__bottom">
          <p className="unlock_store__alert_text">{openDoorData.text}</p>
        </div>
      );
    }
    if (showRedCircle && !canUserEnterTheShop()) {
      const errorMessage = shopErrorMessages?.[ErrorMessagesId.out_of_proximity];
      return (
        <div className="unlock_store__bottom">
          <p className="unlock_store__alert_text">{errorMessage?.errorDescription}</p>
        </div>
      )
    }
  };

  const AGBsButtonClickHandler = () => {
    setTermsCloseButton(false);
    setShowPopup(false);

    localStorage.setItem("Terms", JSON.stringify(true));
  };

  useEffect(() => {
    const localTerms = localStorage.getItem("Terms");
    if ((shop?.termsAndConditionsAccess === "ACCESS" || shop?.termsAndConditionsAccess === "ACCESS_CHECKOUT") && !localTerms) {
      setShowPopup(true);
      return;
    }
    setShowPopup(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shop]);

  useEffect(() => {
    if (isButtonClicked === true) {
      localStorage.setItem("isButtonSwiped", JSON.stringify(true));
    }
  }, [isButtonClicked]);

  useEffect(() => {
    if (shopData && shopData.currentLanguage) {
      i18n.changeLanguage(shopData.currentLanguage.toUpperCase());
    }
  }, [shopData])

  useEffect(() => {
    const localBtnSwipe = localStorage.getItem("isButtonSwipedExit");
    if (localBtnSwipe) {
      localStorage.removeItem("isButtonSwipedExit");
      history.go(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const localGuestUser = localStorage.getItem("guestUser");
    if (localGuestUser) {
      localStorage.removeItem("guestUser");
      history.go(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const redirectUserToSupportPage = async () => {
    await history.push("/support-form");
  }

  return (
    <div className="unlock_store">
      <GeneralPopup
        setShowPopup={setShowPopup}
        showPopup={showPopup}
        type="terms"
        clickHandler={AGBsButtonClickHandler}
        onClosePopup={AGBsButtonClickHandler}
      />
      {showCustomErrorPopup && (
        <CustomErrorPopup
          showPopup={showCustomErrorPopup}
          setShowPopup={setShowCustomErrorPopup}
          errorMessage={shopMessages?.SHOP_STATUS?.CLOSED}
          logErrorIndicator={true}
        />
      )}
      <div className="unlock_store__lang_dropdown_button">
        <ExpandableButton type="green" />
      </div>
      <div className="unlock_store__welcome">
        <p className="unlock_store__welcome_message">
          {shop.shopWelcomeScreenText}
          <br />
          {shop.shopName}
        </p>
        <img
          src={shop.shopLogoUrl}
          alt="Shop logo"
          className="unlock_store__logo"
        />
      </div>
      <div>
        <UnlockAnimation
          showRedCircle={showRedCircle}
          setShowRedCircle={setShowRedCircle}
          isButtonClicked={isButtonClicked}
          setIsButtonClicked={setIsButtonClicked}
          outOfProximity={isOutOfProximity()}
        />
      </div>
      {(isButtonClicked || outOfProximity) && renderErrorMessage()}
      {!isButtonClicked && (
        <div className="unlock_store__bottom">
          <div className="unlock_store__in_store_button">
            <Button
              type="green"
              rightIcon={CheckCircleIcon}
              text={shopMessages?.UNLOCK_STORE_PAGE?.ALREADY_IN_THE_SHOP}
              clickHandler={alreadyInStoreButtonClickHandler}
            />
          </div>
          <ul className="unlock_store__links">
            <li onClick={tAndCLinkHandler}>{shopMessages?.UNLOCK_STORE_PAGE?.T_C}</li>
            <li onClick={redirectUserToSupportPage}>
              <img
                alt="left"
                src={whiteEnvelope}
              />
              {t('SUPPORT.ACTION_TEXT')}
            </li>
          </ul>
        </div>
      )}
    </div>
  );
};

export default UnlockStore;
