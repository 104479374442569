import React, { FC, useContext, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";

import {ShopContext, AxiosContext, NewErrorContext} from "../../context";

import CartItem from "../CartItem/CartItem";
import Button from "../Button/Button";

import exit from "../../assets/icons/green_exit_icon.png";
import save from "../../assets/icons/green_save_icon.png";
import {DebugMessageId} from "../../context/types";

import LogEvents from "../../services/logEvents";

interface ReceiptProps {
  pdfLinkUrl: string;
}

const Receipt: FC<ReceiptProps> = (props) => {
  const { shop, shopMessages } = useContext(ShopContext);
  const history = useHistory();

  const userPlaceholder: SessionUser = JSON.parse(
    localStorage.getItem("guestUser")!
  );

  const saveButtonClickHandler = () => {
    LogEvents.logEvent(DebugMessageId.show_receipt, "Receipt has been opened", "DEBUG");
    window.open(`${props.pdfLinkUrl}`, "_blank");
  };

  const exitButtonClickHandler = () => {
    history.push("/exit");
  };

  const calculateTotal = (items?: CartItemType[]) => {
    if (items)
      return items
        .reduce((ack: number, item) => ack + item.amount * item.priceToDisplay, 0)
        .toFixed(2);

    const initialPrice = 0;

    return initialPrice.toFixed(2);
  };

  const renderCartItems = () => {
    return userPlaceholder?.shoppingCart.map((item) => (
      <CartItem key={item.barcode} item={item} type="receipt" />
    ));
  };

  const shopInfo = JSON.parse(localStorage.getItem("shopInfo")!);

  return (
    <React.Fragment>
      <div className="receipt">
        <div className="receipt__content">
          <div>
            <p className="receipt__title">{shop.shopThankYouText}</p>
            <div className="receipt__cart">
              <div className="receipt__total">
                <p>Total {shopInfo.currency} {calculateTotal(userPlaceholder?.shoppingCart)}</p>
              </div>
              <div className="receipt__render">{renderCartItems()}</div>
            </div>
          </div>
          <div className="receipt__buttons">
            <div className="receipt__button_top">
              <Button
                type="white"
                rightIcon={save}
                clickHandler={saveButtonClickHandler}
                text={shopMessages?.SHOP_PAGE?.SAVE}
              />
            </div>
            <div className="receipt__button_bottom">
              <Button
                type="white"
                rightIcon={exit}
                clickHandler={exitButtonClickHandler}
                text={shopMessages?.SHOP_PAGE?.EXIT_SHOP}
              />
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Receipt;
