import React, { FC } from "react";

import { UserContextProvider } from "./UserContext";
import { ShopContextProvider } from "./ShopContext";
import { BarcodeContextProvider } from "./BarcodeContext";
import { AxiosContextProvider } from "./AxiosContext";
import { ErrorContextProvider } from "./ErrorContext";
import { PaymentTokenizationContextProvider } from "./PaymentTokenizationContext";
import { NewErrorContextProvider } from "./NewErrorContext";
interface GlobalContextProps {
  children: React.ReactNode;
}

const GlobalContextProvider: FC<GlobalContextProps> = (props) => {
  return (
    <AxiosContextProvider>
      <NewErrorContextProvider>
        <ErrorContextProvider>
          <PaymentTokenizationContextProvider>
            <UserContextProvider>
              <ShopContextProvider>
                <BarcodeContextProvider>{props.children}</BarcodeContextProvider>
              </ShopContextProvider>
            </UserContextProvider>
          </PaymentTokenizationContextProvider>
        </ErrorContextProvider>
      </NewErrorContextProvider>
    </AxiosContextProvider>
  );
};

export default GlobalContextProvider;
