import { Redirect, Route } from "react-router-dom";
interface IProtect {
  component: any;
  path: any;
}

export default function ProtectedRoute({
  component: Component,
  ...rest
}: IProtect) {
  const guestUserLocal = JSON.parse(localStorage.getItem("guestUser")!);

  return (
    <Route
      {...rest}
      render={(props) => {
        if (guestUserLocal !== null && guestUserLocal.shopId) {
          return <Component {...props} />;
        } else {
          return (
            <Redirect
              to={{
                pathname: "/",
                state: {
                  from: props.location,
                },
              }}
            />
          );
        }
      }}
    />
  );
}
