import React, { useEffect } from "react";
import { UnlockStoreComp } from "../../components";
import { useHistory } from "react-router-dom";
import { PaymentTokenDetails } from "../../context/types";

const UnlockStore: React.FC = () => {
    const history = useHistory();
    const shopInfo = JSON.parse(localStorage.getItem("shopInfo")!);

    useEffect(() => {
        if (!isShopSelected()) {
            history.push("/qr");
            return;
        }

        if (shopInfo.shopStatus.status === 'CLOSED') {
            history.push('/closed');
        }

        if (shopInfo.shopStatus.status === 'MAINTENANCE') {
            history.push('/maintenance');
        }

        if (shouldBeRedirectToTermsAndConditionsPage()) {
            history.push("/terms-and-conditions");
            return;
        }

        if (shopInfo.paymentTokenizationRequired) {
            const shopData = JSON.parse(localStorage.getItem('shopData')!);
            let paymentTokenDetailsList: PaymentTokenDetails[] = JSON.parse(localStorage.getItem("paymentTokenDetailsList") || '[]');

            const tokenDetails = paymentTokenDetailsList.filter(tokenDetails => tokenDetails.shopId === shopData.shopId);
            const token = tokenDetails[0]?.paymentToken;

            if (token) return;

            history.push("/payment-tokenization/info");
        }
    }, [])

    const isShopSelected = (): boolean => {
        return shopInfo && Object.keys(shopInfo).length > 0;
    }

    const shouldBeRedirectToTermsAndConditionsPage = (): boolean => {
        return !localStorage.getItem("Terms") && (shopInfo.termsAndConditionsAccess === 'ACCESS' || shopInfo.termsAndConditionsAccess === 'ACCESS_CHECKOUT');
    }

    return (
        <div className="unlock_store">
            <UnlockStoreComp />
        </div>
    );
};

export default UnlockStore;
