const TimeWhenSessionExpiresInMs = 3_600_000;
// const FiveSec = 10000;

//TODO: Change method name to the small letter.
export const checkTime = () => {
  const now: number = new Date().getTime();

  // TODO: rename, take valu from local storage
  const getSessionStart = JSON.parse(localStorage.getItem("guestUser")!);

  if (getSessionStart) {
    const sessionCreatedTime: number = new Date(
      getSessionStart.sessionStart
    ).getTime();
    const difference: number = now - sessionCreatedTime;
    if (difference > TimeWhenSessionExpiresInMs) {
      // proslo 1h
      localStorage.removeItem("guestUser");
    }
  }
};
