import React, { useContext, useEffect, useState } from "react";
import { RouteComponentProps, useHistory, useLocation } from "react-router-dom";
import SelectLanguagePopup from "../components/popups/SelectLanguagePopup/SelectLanguagePopup";

import { ShopContext } from "../context";
import { useTranslation } from "react-i18next";
import LogEvents from "../services/logEvents";
import { InfoMessageId, PaymentTokenDetails } from "../context/types";
import config from "../config/config";


function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

interface Props extends RouteComponentProps<{ id: string }> { }

const UrlAuthentication: React.FC<Props> = ({ match, location }) => {
  const { shopData, setShopData, getShopInfo, shopAvailableLanguages } = useContext(ShopContext);

  const query = useQuery();

  const history = useHistory();

  const { i18n } = useTranslation();

  const [showSelectLanguageModal, setShowSelectLanguageModal] = useState(false);

  const shopInfo = JSON.parse(localStorage.getItem('shopInfo')!);

  const doesLanguageBelongToShopLanguages = (languageCode: string): boolean => {
    return shopAvailableLanguages.find(language => language.code.toLowerCase() === languageCode.toLowerCase()) !== undefined;
  }

  const redirectUserAfterGettingShopData = async (response: any) => {
    if (shopData) {
      if (!doesShopDataLanguageExistInShopLanguages(response)) {
        const shopDataLocale = JSON.parse(localStorage.getItem('shopData')!);
        const language = shopDataLocale.currentLanguage.toLowerCase();
        LogEvents.logEvent(InfoMessageId.unsupported_language_opened_the_shop, `User with unsupported language opened the shop. Original Language ${language}`);
        setShowSelectLanguageModal(true);
        return;
      }
    }
    if (response.shopStatus.status === 'CLOSED') {
      await history.push("/closed");
      return;
    }
    if (response.shopStatus.status === 'MAINTENANCE') {
      await history.push("/maintenance");
      return;
    }
    history.push("/enter");
  }

  const doesShopDataLanguageExistInShopLanguages = (shopInfo: any): boolean => {
    const shopDataLocale = JSON.parse(localStorage.getItem('shopData')!);
    return shopInfo.availableLanguages.find((language: any) => language.code.toLowerCase() === shopDataLocale.currentLanguage.toLowerCase()) !== undefined;
  }

  const shopId = match.params.id;
  const inStoreIdentify = location.search.split("&");

  const checkIfInStore = () => {
    if (inStoreIdentify[1] === "inStore") {
      return true;
    }
    return false;
  };

  useEffect(() => {
    const { appVersion } = config;
    const clientAppVersion = localStorage.getItem("appVersion");

    if (clientAppVersion === appVersion) {
      return;
    }

    const shopDataLocale = JSON.parse(localStorage.getItem("shopData")!);
    const paymentTokenDetailsList: PaymentTokenDetails[] = [];

    localStorage.clear();

    caches?.keys().then((names) => {
      names.forEach((name) => {
        caches?.delete(name);
      });
    });

    localStorage.setItem("shopData", JSON.stringify(shopDataLocale));
    localStorage.setItem("paymentTokenDetailsList", JSON.stringify(paymentTokenDetailsList!))
    localStorage.setItem("appVersion", appVersion)
  }, [])

  useEffect(() => {
    const languagePassedViaQueryParams = query.get('lang');
    const shopData = JSON.parse(localStorage.getItem('shopData')!);
    let languageCode = languagePassedViaQueryParams ? languagePassedViaQueryParams : shopData?.currentLanguage;

    if (languageCode) {
      setShopData({ shopId: shopId, currentLanguage: languageCode });
      const data = {
        shopId: shopId,
        currentLanguage: languageCode,
      };
      localStorage.setItem("shopData", JSON.stringify(data));
      i18n.changeLanguage(languageCode.toUpperCase());
      return;
    }

    // format "en-US"
    const browserLocale =
      navigator.languages && navigator.languages.length
        ? navigator.languages[0]
        : navigator.language;

    languageCode = browserLocale.split('-')[0];
    setShopData({ shopId: shopId, currentLanguage: languageCode });
    const data = {
      shopId: shopId,
      currentLanguage: languageCode,
    };
    localStorage.setItem("shopData", JSON.stringify(data));

    if (doesLanguageBelongToShopLanguages(languageCode)) {
      i18n.changeLanguage(languageCode.toUpperCase());
      return;
    }
    i18n.changeLanguage("EN");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const shopDataLocale = JSON.parse(localStorage.getItem('shopData')!);
    const shopDataForGettingShopInfo = {
      ...shopDataLocale,
      shopId
    }
    if (shopData.shopId && shopData.shopId !== '0') {
      getShopInfo(shopDataForGettingShopInfo).then((response: any) => {
        redirectUserAfterGettingShopData(response)
      }).catch((exception: any) => {
        history.push('/qr');
      });
    }
  }, [shopData.shopId]);

  useEffect(() => {
    if (checkIfInStore()) {
      localStorage.setItem("inStore", "true");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const redirectUser = async (data: any) => {
    const response = await getShopInfo(data);
    redirectUserAfterGettingShopData(response);
  }

  return <React.Fragment>
    <SelectLanguagePopup
      availableLanguages={shopInfo ? shopInfo.availableLanguages : []}
      setShowPopup={setShowSelectLanguageModal}
      showPopup={showSelectLanguageModal}
      onClosePopup={redirectUser}
    />
  </React.Fragment>;
};

export default UrlAuthentication;
