import React, { useContext, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import LogEvents from "../../services/logEvents";

import {
  BarcodeContext,
  UserContext,
  AxiosContext,
  ShopContext,
  ErrorContext, NewErrorContext,
} from "../../context";

import { Button, Confirm, QuestionPopup, FirstScan, Scandit, Cart } from "..";

import payIcon from "../../assets/icons/pay_icon.png";
import CustomErrorPopup from "../popups/CustomErrorPopup/CustomErrorPopup";
import {ErrorMessagesId, InfoMessageId} from "../../context/types";

const Shop: React.FC = () => {
  const { handleAddToCart, handleRemoveFromCart } = useContext(BarcodeContext);

  const { guestUser, setGuestUser } = useContext(UserContext);
  const { shop, shopMessages } = useContext(ShopContext);
  const { error, setShowErrorPopup } = useContext(ErrorContext);
  const { setErrorId } = useContext(NewErrorContext);
  const { axiosInstance } = useContext(AxiosContext);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isTutorialPlayed, setIsTutorialPlayed] = useState<boolean>(true);

  const { t } = useTranslation();

  const [showScanner, setShowScanner] = useState(true);

  const [showLeaveShopPopup, setShowLeaveShopPopup] = useState(false);

  const [showCustomErrorPopup, setShowCustomErrorPopup] = useState(false);

  const [showConfirm, setShowConfirm] = useState(false);

  const [showProductsInCart, setShowProductsInCart] = useState(true);

  const [showTotal, setShowTotal] = useState(true);

  const [showPayButton, setShowPayButton] = useState(true);

  type PaymentParams = {
    id: string;
  };

  let { id } = useParams<PaymentParams>();

  const payButtonClickHandler = () => {
    setShowScanner(false);
    setShowPayButton(false);
    setShowConfirm(true);
  };

  const cartStyles = showScanner ? "shop__cart" : "shop__cart--without_scanner";

  const userPlaceholder: SessionUser = JSON.parse(
    localStorage.getItem("guestUser")!
  );

  const cartMap = userPlaceholder?.shoppingCart.map((item) => ({
    id: item.productId,
    amount: item.amount,
    price: item.price,
    weight: item.weight,
  }));

  const checkoutData = () => {
    return {
      transactionId: id,
      shopId: userPlaceholder?.shopId,
      status: "FAIL",
      cart: cartMap,
    };
  };

  const sendCheckoutData = () => {
    const data = checkoutData();

    const shopDataLocal = JSON.parse(localStorage.getItem('shopData')!);
    const language = shopDataLocal.currentLanguage;

    // LogEvents.logEvents("paymentStatusFailed", "Payment failed");
    LogEvents.logEvent(InfoMessageId.payment_failed, "Payment failed");

    return axiosInstance({
      method: "POST",
      url: `/sendCheckoutData/${userPlaceholder?.shopId}?lang=${language}`,
      data,
    })
      .then(() => {})
      .catch((err: any) => {
        setErrorId(ErrorMessagesId.connectivity_issue);
      });
  };

  useEffect(() => {
    const element = document.getElementById("total");
    element?.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "nearest",
    });
  }, [guestUser, showConfirm]);

  useEffect(() => {
    if (shop.shopName) {
      if (shop.walleeSpaceId === 0 || shop.walleeSpaceId === undefined)
        setShowCustomErrorPopup(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shop]);

  useEffect(() => {
    if (!guestUser?.shoppingCart?.length) {
      const localUser = JSON.parse(localStorage.getItem("guestUser")!);
      setGuestUser(localUser);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [guestUser.shopId]);

  useEffect(() => {
    if (id) {
      sendCheckoutData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    if (error) {
      setShowErrorPopup(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  useEffect(() => {
    if (guestUser?.shoppingCart?.length > 1) {
      localStorage.setItem("isAnimationPlayed", "true");
    }
  }, [guestUser?.shoppingCart?.length]);

  useEffect(() => {
    const y = document.getElementsByClassName("swipeable-list")[0];
    const isAnimationPlayed = JSON.parse(
      localStorage.getItem("isAnimationPlayed")!
    );
    if (isAnimationPlayed && y) {
      y.className = "animation-list";
    }
  }, [guestUser?.shoppingCart?.length]);

  useEffect(() => {
    let appOpenIndicator;
    if(localStorage.getItem("appOpenIndicator")) {
      appOpenIndicator = localStorage.getItem("appOpenIndicator")
    }

    if(appOpenIndicator === "webAppWithoutShopName" ) {
      // LogEvents.logEvents("webAppOpened", "");
      const shopId = JSON.parse(localStorage.getItem('shopData')!).shopId;
      LogEvents.logEvent(InfoMessageId.shop_opened, `Shop id ${shopId} ${shop?.shopName} started`);
    }
  }, [localStorage.getItem("sessionId")])

  return (
    <div className="shop">
      <div id="scanner" className="shop__scanner">
        <Scandit onItemScan={handleAddToCart} showScanner={showScanner} />
      </div>
      {guestUser?.shoppingCart?.length === 0 && (
        <div id="first_scan" className="shop__first_scan">
          <FirstScan setShowLeaveShopPopup={setShowLeaveShopPopup} />
        </div>
      )}
      {guestUser?.shoppingCart?.length !== 0 && (
        <>
          {showTotal && (
            <div className={cartStyles} id="cart">
              <Cart
                cartItems={guestUser?.shoppingCart}
                addToCart={handleAddToCart}
                removeFromCart={handleRemoveFromCart}
                showProductsInCart={showProductsInCart}
                isTutorialPlayed={isTutorialPlayed}
              />
            </div>
          )}
          {showPayButton && (
            <div className="shop__button_container">
              <div className="shop__button">
                <Button
                  type="green"
                  leftIcon={payIcon}
                  text={shopMessages?.SHOP_PAGE?.PAY_SHOPPING_CART}
                  clickHandler={payButtonClickHandler}
                />
              </div>
            </div>
          )}
        </>
      )}
      {showLeaveShopPopup && (
        <QuestionPopup
          setShowPopup={setShowLeaveShopPopup}
          showPopup={showLeaveShopPopup}
        />
      )}
      {showCustomErrorPopup && (
        <CustomErrorPopup
          showPopup={showCustomErrorPopup}
          setShowPopup={setShowCustomErrorPopup}
          errorMessage={t("POPUPS.ERROR_POPUP.UNDEFINED_SPACE_ID")}
          logErrorIndicator={true}
        />
      )}
      {showConfirm && (
        <div className="shop__confirm">
          <Confirm
            setShowProductsInCart={setShowProductsInCart}
            setShowConfirm={setShowConfirm}
            setShowScanner={setShowScanner}
            setShowPayButton={setShowPayButton}
            setShowTotal={setShowTotal}
            cartItems={guestUser?.shoppingCart}
          />
        </div>
      )}
    </div>
  );
};

export default Shop;
