import React, { useContext, useEffect, useState } from "react";
import { Button, ExpandableButton } from "../../components";
import {
  ShopContext,
} from "../../context";
import refreshIcon from "../../assets/icons/grey_sync_circle_icon.png";
import {useHistory} from "react-router-dom";

const MaintenancePage: React.FC = () => {
  const { shopData, getShopInfo, shopMessages } = useContext(ShopContext);
  const history = useHistory();

  const [text, setText] = useState("");

  useEffect(() => {
    const shopDataLocal = JSON.parse(localStorage.getItem("shopData")!);

    getShopInfo(shopDataLocal).then((result: any) => {
      if (result.shopStatus.status === 'MAINTENANCE') {
        setText(result.shopStatus.text);
        return;
      }
      if (result.shopStatus.status === 'CLOSED') {
        history.push("/closed");
        return;
      }

      history.push("/enter");
    });
  }, [shopData]);

  // todo: refactor - same method in Failed page!!!
  const refresh = async () => {
    window.location.reload(false);
  };

  return (
    <div className="payment-tokenization info">
      <div className="payment-tokenization__lang_dropdown_button">
        <ExpandableButton type="green" />
      </div>
      <div className="card">
        <div
          className="content"
          dangerouslySetInnerHTML={{ __html: text }}
        ></div>
        <Button
          type="white"
          rightIcon={refreshIcon}
          clickHandler={() => refresh()}
          text={shopMessages?.SHOP_STATUS?.REFRESH}
        />
      </div>
    </div>
  );
};

export default MaintenancePage;
