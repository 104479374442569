import axios from "axios";

import Store from "../store/store";

import config from "../config/config";


class LogEvents {
    data: any = {};
    shopIdAndLanguage: any;
    shopId: any;
    language: any;
    sessionId : any;
    level: any;
    shopData: any;
    message: any;
    browser: any;
    device : any;

    androidOrIOS() {
        const userAgent = navigator.userAgent;
        if(/android/i.test(userAgent)){
            return 'android';
        };
        if(/iPad/i.test(userAgent)){
            return 'iPad';
        };
        if(/iPhone/i.test(userAgent)){
            return 'iphone';
        };

    }

    browserVersion() {
        var sBrowser, sUsrAg = navigator.userAgent;

        if (sUsrAg.indexOf("Firefox") > -1) {
        sBrowser = "Mozilla Firefox";
        } else if (sUsrAg.indexOf("SamsungBrowser") > -1) {
        sBrowser = "Samsung Internet";
        } else if (sUsrAg.indexOf("Opera") > -1 || sUsrAg.indexOf("OPR") > -1) {
        sBrowser = "Opera";
        } else if (sUsrAg.indexOf("Trident") > -1) {
        sBrowser = "Microsoft Internet Explorer";
        } else if (sUsrAg.indexOf("Edge") > -1) {
        sBrowser = "Microsoft Edge";
        } else if (sUsrAg.indexOf("Chrome") > -1) {
        sBrowser = "Google Chrome or Chromium";
        } else if (sUsrAg.indexOf("Safari") > -1) {
        sBrowser = "Apple Safari";
        } else {
        sBrowser = "unknown";
        }

        return sBrowser;
    }

    logEvent(id: number, msg: string, logLevel: "INFO"|"DEBUG"|"ERROR"|"WARN" = "INFO") {
        const browser = this.browserVersion();
        const device = this.androidOrIOS();

        this.shopIdAndLanguage = JSON.parse(localStorage.getItem("shopData")!);
        this.shopId = this.shopIdAndLanguage?.shopId;
        this.language = this.shopIdAndLanguage?.currentLanguage;
        this.sessionId = localStorage.getItem("sessionId");
        this.shopData = Store.state.shop;

        if (logLevel === "DEBUG" && !this.shopData?.debugMode) return;

        this.browser = browser;
        this.level = logLevel;
        this.message = msg;

        if(device) {
            this.device = device;
        } else this.device = "Not Mobile";

        // client request: The messages are now sent with “level: DEBUG”, can you change that in order to send the DEBUG messages as well with “level: INFO”.
        if (this.level === 'DEBUG') {
            this.level = "INFO";
        }

        this.data.shopId = this.shopId;
        this.data.sessionId = this.sessionId;
        this.data.language = this.language;
        this.data.browser = this.browser;
        this.data.device = this.device;
        this.data.level = this.level;
        this.data.message = `Event ID: ${id}: ` + this.message;

        axios
            .post("https://deploy.screenfood.com/sfn-cms-adapter-unmanned-store/unmanned/v1/log", this.data, {
                auth:{
                    username: config.backendAuth.username,
                    password: config.backendAuth.password
                }
            })
            .catch(e => {
                console.log(e);
            })
    }
}

export default new LogEvents();
