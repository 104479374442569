import React, { FC, createContext, useState, useEffect } from "react";

import axios from "axios";

import config from "../config/config";

interface IAxiosContext {
  displayLoader: boolean;
  setDisplayLoader(data: any): void;
  paymentLinkClicked: boolean;
  setPaymentLinkClicked(data: any): void;
  axiosInstance: any;
}

const AxiosContext = createContext({} as IAxiosContext);

const { backendAuth, backendUrl, paymentUrl } = config;

interface AxiosContextProps {
  children: React.ReactNode;
}

const AxiosContextProvider: FC<AxiosContextProps> = (props) => {
  const [displayLoader, setDisplayLoader] = useState(false);
  const [paymentLinkClicked, setPaymentLinkClicked] = useState(false);

  const axiosInstance = axios.create({
    baseURL: backendUrl,
    auth: backendAuth
  });

  const hideLoaderIfStuck = () => {
    setTimeout(() => {
      setDisplayLoader(false);
    }, 1000);
  };

  const hidePaymentLoader = () => {
    setTimeout(() => {
      setDisplayLoader(false);
    }, 20000);
  };

  useEffect(() => {
    let timeout;

    if (displayLoader && !paymentLinkClicked) {
      timeout = hideLoaderIfStuck();

      return;
    }

    if (displayLoader && paymentLinkClicked) {
      timeout = hidePaymentLoader();

      return;
    }

    clearTimeout(timeout);
  }, [displayLoader]);

  const providerValue = {
    displayLoader,
    setDisplayLoader,
    axiosInstance,
    paymentLinkClicked,
    setPaymentLinkClicked
  };

  return (
    <AxiosContext.Provider value={providerValue}>
      {props.children}
    </AxiosContext.Provider>
  );
};

export { AxiosContext, AxiosContextProvider };
