class LocalStorageService {
  public save(guestUser: any): void {
    localStorage.setItem("guestUser", JSON.stringify(guestUser));
  }

  public get(): void {
    localStorage.getItem("guestUser");
  }
}

export default new LocalStorageService();
