import React, { FC, useState, useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import { useTranslation } from "react-i18next";

import { AxiosContext, NewErrorContext, ShopContext } from "../../context";

import { CustomErrorPopup, ExpandableButton, GeneralPopup } from "..";
import RedSlideButton from "../RedSlideButton/RedSlideButton";

import * as FaIcons from "react-icons/fa";
import { FiMenu } from "react-icons/fi";
import * as AiIcons from "react-icons/ai";

import LogEvents from "../../services/logEvents";
import whiteEnvelope from "../../assets/icons/mail_white.png";
import { ErrorMessagesId, InfoMessageId } from "../../context/types";

interface NavBarProps {
  withoutLogo?: boolean;
  setShowRedCircle(data: boolean): void;
  setIsButtonClicked(data: boolean): void;
}

const Navbar: FC<NavBarProps> = (props) => {
  const { withoutLogo, setShowRedCircle, setIsButtonClicked } = props;

  const {
    shop,
    canUserEnterTheShop,
    openDoor,
    getGeolocation,
    setTermsCloseButton,
    shopData,
    showTermsPopup,
    setShowTermsPopup,
    shopMessages
  } = useContext(ShopContext);

  const { setDisplayLoader } = useContext(AxiosContext);
  const { setErrorId } = useContext(NewErrorContext);

  const history = useHistory();
  const { t, i18n } = useTranslation();

  const [showSidebar, setShowSidebar] = useState(false);
  const [showHeader, setShowHeader] = useState(true);
  const [showImprintPopup, setShowImprintPopup] = useState(false);
  const [showCustomErrorPopup, setShowCustomErrorPopup] = useState(false);

  // this is quick fix for support form link language
  useEffect(() => {
    if (shopData && shopData.currentLanguage) {
      i18n.changeLanguage(shopData.currentLanguage.toUpperCase());
    }
  }, [shopData]);

  const toggleSidebar = () => {
    setShowSidebar(!showSidebar);
    setShowHeader(!showHeader);
  };

  const successCallback = () => {
    if (canUserEnterTheShop()) {
      setDisplayLoader(true);
      let openDoorIdAndDirection = {
        id: shopData.shopId,
        direction: { direction: "EXIT" },
      };
      openDoor(openDoorIdAndDirection).then(() => {
        LogEvents.logEvent(InfoMessageId.emergency_exit, "Emergency exit triggered");
        // LogEvents.logEvents("emergencyExit", "");
        setDisplayLoader(false);
        setShowSidebar(false);
        setShowHeader(true);
        history.push("/exit");
      });
      return;
    }
    setShowSidebar(false);
    setShowHeader(true);
    setShowRedCircle(true);
    history.push("/exit");
  };

  const errorCallback = () => {
    setErrorId(ErrorMessagesId.no_access_to_location);
    setShowSidebar(false);
    setShowHeader(true);
  };

  const clickedButtonHandler = (e: boolean) => {
    setIsButtonClicked(true);
    if (shop?.locationCheckRequired) {
      getGeolocation(successCallback, errorCallback);
      return;
    }
    let openDoorIdAndDirection = {
      id: shopData.shopId,
      direction: { direction: "EXIT" },
    };
    openDoor(openDoorIdAndDirection).then(() => {
      LogEvents.logEvent(InfoMessageId.emergency_exit, "Emergency exit triggered");
      // LogEvents.logEvents("emergencyExit", "");
      setDisplayLoader(false);
      setShowSidebar(false);
      setShowHeader(true);
      history.push("/exit");
    });
  };

  const tAndCLinkHandler = () => {
    setTermsCloseButton(true);
    setShowTermsPopup(true);
  };

  const imprintLinkHandler = () => {
    setShowImprintPopup(true);
  };

  const alreadyInStoreButtonClickHandler = () => {
    if (shop?.shopStatus?.status === "OPEN") {
      getGeolocation(successCallback, errorCallback);
      localStorage.removeItem("inStore");
      return;
    }
    setShowCustomErrorPopup(true);
  };

  const redirectUserToSupportPage = async () => {
    await history.push('/support-form')
  }

  const inStoreCheck = () => {
    const localInStore = JSON.parse(localStorage.getItem("inStore")!);
    if (localInStore === true) {
      alreadyInStoreButtonClickHandler();
    }
  };

  const AGBsButtonClickHandler = () => {
    setShowTermsPopup(false);
    localStorage.setItem("Terms", JSON.stringify(true));
    setTermsCloseButton(false);
    inStoreCheck();
  };

  return (
    <div className="navbar">
      <GeneralPopup
        setShowPopup={setShowTermsPopup}
        showPopup={showTermsPopup}
        type="terms"
        clickHandler={AGBsButtonClickHandler}
        onClosePopup={AGBsButtonClickHandler}
      />
      <GeneralPopup
        setShowPopup={setShowImprintPopup}
        showPopup={showImprintPopup}
        type="imprint"
        clickHandler={() => setShowImprintPopup(false)}
        onClosePopup={() => setShowImprintPopup(false)}
      />
      {showCustomErrorPopup && (
        <CustomErrorPopup
          showPopup={showCustomErrorPopup}
          setShowPopup={setShowCustomErrorPopup}
          errorMessage={t("POPUPS.ERROR_POPUP.SHOP_IS_NOT_OPEN")}
        />
      )}
      {showHeader && !showTermsPopup && (
        <div className="navbar__header">
          <div className="navbar__hidden_element">
            <FaIcons.FaAddressBook />
          </div>
          {!withoutLogo && (
            <img
              src={shop.shopLogoUrl}
              className="navbar__logo"
              alt="Shop logo"
            />
          )}
          <div className="navbar__hamburger">
            <FiMenu
              color={`${shop?.shopCustomCss?.primaryColor}`}
              size="30"
              onClick={toggleSidebar}
            />
          </div>
        </div>
      )}
      <div className="navbar__sidebar_position">
        <TransitionGroup component={null}>
          {showSidebar && (
            <CSSTransition timeout={500} classNames="sidebar">
              <div className="navbar__overlay">
                <div
                  className={
                    showSidebar ? "navbar__sidebar--active" : "navbar__sidebar"
                  }
                >
                  <div className="navbar__close">
                    <AiIcons.AiOutlineClose
                      size="25"
                      className="navbar_close"
                      color={`${shop?.shopCustomCss?.white}`}
                      onClick={toggleSidebar}
                    />
                  </div>
                  <div className="navbar__sidebar_content">
                    <div className="navbar__language_button">
                      <ExpandableButton
                        type="white"
                        setShowSidebar={setShowSidebar}
                        setShowHeader={setShowHeader}
                      />
                    </div>
                    <ul className="navbar__agb_impressum">
                      <li onClick={tAndCLinkHandler}>{shopMessages?.NAVBAR?.T_C}</li>
                      &#160; | &#160;
                      <li onClick={imprintLinkHandler}>
                        {shopMessages?.NAVBAR?.IMPRINT}
                      </li>
                    </ul>
                    <p className="navbar__hilfe">{shopMessages?.NAVBAR?.HELP}</p>
                    <ul className="navbar__links">
                      {shop?.messages?.SHOP_MENU.map((item, index) => {
                        return (
                          <li key={index} className="navbar__link">
                            <a
                              href={item.link}
                              target="_blank"
                              rel="noreferrer"
                            >
                              {item.label}
                            </a>
                          </li>
                        );
                      })}
                      <li className="navbar__link">
                        <p
                          onClick={redirectUserToSupportPage}
                        >
                          <img
                            alt="left"
                            src={whiteEnvelope}
                          />
                          {t('SUPPORT.ACTION_TEXT')}
                        </p>
                      </li>
                    </ul>
                  </div>
                  <div className="navbar__emergency_button">
                    <RedSlideButton
                      onSuccess={() => clickedButtonHandler(true)}
                      text={shopMessages?.NAVBAR?.EMERGENCY}
                    />
                  </div>
                </div>
              </div>
            </CSSTransition>
          )}
        </TransitionGroup>
      </div>
    </div>
  );
};

export default Navbar;
