import { FC, useContext } from "react";
import { ShopContext } from "../../context";

import { Button } from "../../components";

import exit from "../../assets/icons/green_exit_icon.png";

import LogEvents from "../../services/logEvents"
import {InfoMessageId} from "../../context/types";

interface FirstScanProps {
  setShowLeaveShopPopup(data: boolean): void;
}

const FirstScan: FC<FirstScanProps> = (props) => {
  const { setShowLeaveShopPopup } = props;

  const { shop, shopMessages } = useContext(ShopContext);

  const buttonClickHandler = () => {
    LogEvents.logEvent(InfoMessageId.leave_shop_from_product_scan, "Leave shop triggered on article scan screen");
    // LogEvents.logEvents("leaveTheShopTriggered", "")
    setShowLeaveShopPopup(true);
  };

  const textStyle = shop.shopFirstEanText
    ? "first_scan__text"
    : "first_scan__background_text";

  return (
    <div className="first_scan">
      <div className="first_scan__content">
        <p className={textStyle}>
          {" "}
          {shop.shopFirstEanText
            ? shop.shopFirstEanText
            : "Richten Sie Ihre Kamera auf den Barcodes des Artikels um ihn dem Einkaufskorb hinzuzufügen."}
        </p>
        <div className="first_scan__button">
          <Button
            type="white"
            rightIcon={exit}
            clickHandler={buttonClickHandler}
            text={shopMessages?.SHOP_PAGE?.EXIT_SHOP}
          />
        </div>
      </div>
    </div>
  );
};

export default FirstScan;
