import { FC } from "react";

import LogEvents from "../../services/logEvents";
import {InfoMessageId} from "../../context/types";

type ButtonType = "white" | "green";
interface ButtonProps {
  icon?: string;
  rightIcon?: string;
  leftIcon?: string;
  text: string;
  type: ButtonType;
  disabled?: boolean;
  loggingId?: string
  clickHandler(): void;
}

const Button: FC<ButtonProps> = (props) => {
  const { icon, leftIcon, rightIcon, text, type, disabled, loggingId, clickHandler } =
    props;

  const buttonStyles = () => {
    if (type === "white" && !disabled) {
      return "button_component";
    } else if (type === "white" && disabled) {
      return "button--disabled";
    } else if (type === "green" && !disabled) {
      return "green_button_component";
    } else if (type === "green" && disabled) {
      return "green_button--disabled";
    }
  };


  const onClick = () => {
    if(loggingId === "shop again") {
      LogEvents.logEvent(InfoMessageId.shop_again, "Shop again triggered");
      // LogEvents.logEvents("shopAgain", "");
    }
    if (!disabled) clickHandler();
  };

  return (
    <button className={buttonStyles()} type="submit" onClick={onClick}>
      {leftIcon && (
        <img
          className="button_component__left_icon"
          alt="button"
          src={leftIcon}
        />
      )}
      <div>
        <p className="button_component__text">{text}</p>
        {icon && <img src={icon} alt="icon" />}
      </div>
      {rightIcon && (
        <img
          className="button_component__right_icon"
          alt="button"
          src={rightIcon}
        />
      )}
    </button>
  );
};

export default Button;
