import React, {FC, useContext} from "react";
import { useTranslation } from "react-i18next";

import { Button } from "../..";

import { PopUpTemplate } from "../../../templates";

import close from "../../../assets/icons/white_close_icon.png";

import { ShopContext } from "../../../context";

interface CustomErrorPopupProps {
  setShowPopup(data: boolean): void;
  showPopup: boolean;
  errorMessage: string;
  logErrorIndicator? : Boolean
}

const CustomErrorPopup: FC<CustomErrorPopupProps> = (props) => {
  const { showPopup, setShowPopup, errorMessage, logErrorIndicator } = props;
  const {shopMessages} = useContext(ShopContext);

  const { t } = useTranslation();

  const whiteButtonClickHandler = () => {
    setShowPopup(false);
  };

  const closeButtonClickHandler = () => {
    setShowPopup(false);
  };

  return (
    <React.Fragment>
      <PopUpTemplate showPopup={showPopup}>
        <div className="custom_error_popup">
          <div className="custom_error_popup__content">
            <div className="custom_error_popup__head">
              <img
                src={close}
                alt="Close modal"
                className="custom_error_popup__close"
                onClick={closeButtonClickHandler}
              />
              <p className="custom_error_popup__title">
                {t("POPUPS.ERROR_POPUP.TITLE")}
              </p>
              <p className="custom_error_popup__subtitle">
                {t("POPUPS.ERROR_POPUP.SUBTITLE")}
              </p>
              <p className="custom_error_popup__text">{errorMessage}</p>
            </div>
            <div className="custom_error_popup__button">
              <Button
                type="white"
                clickHandler={whiteButtonClickHandler}
                text={shopMessages?.POPUPS?.ERROR_POPUP?.BUTTON_TEXT}
              />
            </div>
          </div>
        </div>
      </PopUpTemplate>
    </React.Fragment>
  );
};

export default CustomErrorPopup;
