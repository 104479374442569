import React, { useContext, useEffect, useState } from "react";

import { AxiosContext, NewErrorContext, ShopContext } from "../../context";

import { Receipt, Navbar } from "../../components";
import LogEvents from "../../services/logEvents";
import { CheckoutData, DebugMessageId, ErrorMessagesId, InfoMessageId } from "../../context/types";
import { useParams } from "react-router-dom";
import animationData from "../../components/Loader/loader.json";
import LottieLoader from "react-lottie-loader";

type PaymentParams = {
  id: string;
};

const ReceiptPage: React.FC = () => {
  const { setShowRedCircle, setIsButtonClicked } = useContext(ShopContext);
  const { axiosInstance } = useContext(AxiosContext);
  const { setErrorId } = useContext(NewErrorContext);
  const [pdfLink, setPdfLink] = useState("");
  const [showReceiptPageLoader, setShowReceiptPageLoader] = useState(false)

  let { id } = useParams<PaymentParams>();

  const checkoutData = (): CheckoutData => {
    const shopDataLocal = JSON.parse(localStorage.getItem("shopData")!);
    return {
      transactionId: id,
      shopId: shopDataLocal?.shopId,
      status: "SUCCESS"
    };
  };

  useEffect(() => {
    const sendCheckoutData = () => {
      setShowReceiptPageLoader(true);
      const shopDataLocal = JSON.parse(localStorage.getItem("shopData")!);

      let data = checkoutData();
      return axiosInstance({
        method: "POST",
        url: `/sendCheckoutData/${shopDataLocal.shopId}?lang=${shopDataLocal.currentLanguage}`,
        data
      })
        .then((response: any) => {
          setShowReceiptPageLoader(false);
          setPdfLink(response.data.checkoutData);
          LogEvents.logEvent(DebugMessageId.purchase_order_sent_to_backend, `Purchase order sent to backend, purchase order id: ${id}`, "DEBUG");
        })
        .catch((err: any) => {
          setErrorId(ErrorMessagesId.connectivity_issue);
        });
    };
    if (id) {
      sendCheckoutData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    let data = checkoutData()
    LogEvents.logEvent(InfoMessageId.payment_success, `Payment successful, transaction id ${data.transactionId}`);
  }, [])

  return (
    <div className="receipt_page">
      {showReceiptPageLoader && (
        <div className="loader">
          <LottieLoader
            animationData={animationData}
            autoplay={showReceiptPageLoader}
            className="loader__lottie"
          />
        </div>
      )}
      <Navbar
        setShowRedCircle={setShowRedCircle}
        setIsButtonClicked={setIsButtonClicked}
      />
      <div className="receipt_page__receipt">
        <Receipt pdfLinkUrl={pdfLink} />
      </div>
    </div>
  );
};

export default ReceiptPage;
