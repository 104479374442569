import { useEffect, useState, FC, useContext } from "react";

import * as ScanditSDK from "scandit-sdk";
import { BarcodePicker, Barcode, ScanSettings } from "scandit-sdk";

import { ErrorContext, NewErrorContext, ShopContext } from "../../context";

import config from "../../config/config";

import ErrorPopup from "../popups/ErrorPopup/ErrorPopup";

import "./Scandit.scss";
import LogEvents from "../../services/logEvents";
import logEvents from "../../services/logEvents";
import { DebugMessageId, ErrorMessagesId, InfoMessageId, WarningMessageId } from "../../context/types";

const { engineLocation } = config;

interface ScanditProps {
  onItemScan(item: CartItemType): void;
  showScanner: boolean;
}

const Scandit: FC<ScanditProps> = (props) => {
  const { onItemScan, showScanner } = props;

  const { error, showErrorPopup, setShowErrorPopup } =
    useContext(ErrorContext);

  const { shopData, shop, logOpenDoor, logAlreadyInStore, setLogOpenDoor, setLogAlreadyInStore } = useContext(ShopContext);

  useEffect(() => {
    if (logOpenDoor) {
      // LogEvents.logEvents("openDoorTriggered", "Entry page: Open door triggered");
      LogEvents.logEvent(InfoMessageId.open_door_triggered, "Entry page: Open door triggered");
      setLogOpenDoor(false);
    }

    if (logAlreadyInStore) {
      // LogEvents.logEvents("openDoorTriggered", "Entry page: Already in shop button triggered");
      LogEvents.logEvent(InfoMessageId.already_in_shop, "Entry page: Already in shop button triggered");
      setLogAlreadyInStore(false);
    }
  }, [])


  const shopInfo = JSON.parse(localStorage.getItem('shopInfo')!);

  const [scanditErrorCounter, setScanditErrorCounter] = useState(true)
  const [barcodePickerCreated, setBarcodePickerCreated] = useState(false);
  const [libraryConfigured, setLibraryConfigured] = useState(false);
  const [barcodePicker, setBarcodePicker] = useState<any>(undefined);
  const { errorId, setErrorId, setErrorMessageForLog } = useContext(NewErrorContext);

  const extractFirstTwoNumbers = (code: string) => {
    return code.slice(0, 2);
  };

  const getProductIdFromBarcode = (code: string) => {
    if (!checkIsSpecialPriceBarcode(code) && !checkIsSpecialWeightBarcode(code)) {
      return code;
    }

    return code.slice(0, -6) + "000000";
  };

  const checkIsSpecialBarcode = (code: string) => {
    return checkIsSpecialWeightBarcode(code) || checkIsSpecialPriceBarcode(code);
  }

  const checkIsSpecialPriceBarcode = (code: string) => {
    let firstTwoNumbers = extractFirstTwoNumbers(code);

    if (
      firstTwoNumbers === "21" ||
      firstTwoNumbers === "22" ||
      firstTwoNumbers === "24"
    ) {
      return true;
    }

    return false;
  };

  const checkIsSpecialWeightBarcode = (code: string) => {
    let firstTwoNumbers = extractFirstTwoNumbers(code);

    if (
      firstTwoNumbers === "23" ||
      firstTwoNumbers === "28" ||
      firstTwoNumbers === "29"
    ) {
      return true;
    }

    return false;
  };

  const getPriceFromBarcode = (code: string) => {
    let rawPrice = code.slice(7, 12);
    let frances = rawPrice.slice(0, 3).replace(/^0+/, "");
    if (frances === "") {
      frances = "0";
    }
    let cents = rawPrice.slice(3, 5);
    let price = frances + "." + cents;

    return price;
  };

  const getWeightFromBarcode = (code: string) => {
    let rawWeight = code.slice(7, 12);
    let weight = (parseInt(rawWeight.replace(/^0+/, "")) / 1000).toString();

    return weight;
  };

  const getItem = (barcode: string, itemId: string, price?: string, weight?: string) => {
    const item = shopData?.inventory?.find(
      (item: { productId: string }) => item.productId === itemId
    );

    if (item === undefined) {
      return;
    }

    const copiedItem = {
      ...item
    };

    const shouldRoundProductPrice = shopInfo.roundProductPrice;

    copiedItem.barcode = barcode;

    if (price) {
      const priceToDisplay = Number(parseFloat(price).toFixed(2));
      copiedItem.priceToDisplay = shouldRoundProductPrice ?
        Number(roundProductPrice(priceToDisplay).toFixed(2)) :
        priceToDisplay;
      copiedItem.price = copiedItem.priceToDisplay;
      return copiedItem;
    }

    if (weight) {
      copiedItem.weight = weight;
      const priceToDisplay = Number((copiedItem.price * parseFloat(copiedItem.weight)).toFixed(2));
      copiedItem.price = Number(copiedItem.price);
      copiedItem.priceToDisplay = shouldRoundProductPrice ?
        Number(roundProductPrice(priceToDisplay).toFixed(2)) :
        priceToDisplay;
      return copiedItem;
    }

    copiedItem.priceToDisplay = copiedItem.price;

    return copiedItem;
  };

  const roundProductPrice = (price: number) => {
    const factor = 0.05;

    return Math.round(price / factor) * factor;
  }

  const scanItem = (barcode: string) => {
    let productId = getProductIdFromBarcode(barcode);

    logScannedItem(barcode);

    if (checkIsSpecialPriceBarcode(barcode)) {
      let specialPrice = getPriceFromBarcode(barcode);

      return getItemFromBarcode(barcode, productId, specialPrice, undefined);
    }

    if (checkIsSpecialWeightBarcode(barcode)) {
      let specialWeight = getWeightFromBarcode(barcode);

      return getItemFromBarcode(barcode, productId, undefined, specialWeight);
    }
    return getItemFromBarcode(barcode, productId);
  };

  const logScannedItem = (barcode: string) => {
    const isSpecialBarcode = checkIsSpecialBarcode(barcode);

    logEvents.logEvent(DebugMessageId.article_scanned, `Article ${barcode} scanned, EAN code EAN-13`, "DEBUG");

    if (isSpecialBarcode) {
      logEvents.logEvent(DebugMessageId.special_barcode, formatSpecialDebugMessage(barcode), "DEBUG");
    }
  }

  const formatSpecialDebugMessage = (barcode: string) => {
    let message = 'Special barcode identified ' + barcode + ': ';
    let type = checkIsSpecialWeightBarcode(barcode) ? 'weight' : 'price';
    let caseNumber = barcode.slice(0, 2);
    let article = barcode.slice(2, 7);
    let weightOrPrice = barcode.slice(7, 12);
    let checkDigit = barcode.slice(12);

    message += `Case ${type} - case number: ${caseNumber}, article ${article}, weight/price ${weightOrPrice}, check digit ${checkDigit}`;

    return message;
  }

  const getItemFromBarcode = (
    barcode: string,
    itemId: string,
    price?: string,
    weight?: string
  ) => {
    const item = getItem(barcode, itemId, price, weight);

    if (item !== undefined) {
      onItemScan(item);
      return;
    }

    setErrorId(ErrorMessagesId.no_match_for_scanned_product);
    LogEvents.logEvent(WarningMessageId.article_not_found, `Article ${itemId} not found in shop ${shopData.shopId} ${shop.shopName}`, "WARN");
    // LogEvents.logEvent(ErrorMessagesId.no_match_for_scanned_product, `Article ${itemId} not found in shop ${shopData.shopId} ${shop.shopName}`, "ERROR");
  };

  useEffect(() => {
    if (shopData.inventory && !barcodePickerCreated) {
      ScanditSDK.configure(config.scanditKey, {
        engineLocation,
      }).then(() => {
        setLibraryConfigured(true);
      })
        .catch(err => {
          if (scanditErrorCounter) {
            setErrorMessageForLog(err.response.data);
            setErrorId(ErrorMessagesId.scan_cannot_be_used);
            // LogEvents.logEvent(ErrorMessagesId.scan_cannot_be_used, "A problem occurred while scanning or accessing the camera. Please try again later or report the problem using the contact form.", "ERROR");
            setScanditErrorCounter(false);
          }
        })
    }
  }, [shopData.inventory, barcodePickerCreated]);

  const [divElement, setDivElement] = useState(
    document.getElementById("scandit-barcode-picker")
  );

  useEffect(() => {
    if (showScanner) {
      setDivElement(document.getElementById("scandit-barcode-picker"));
    }
  }, [showScanner]);

  useEffect(() => {
    if (libraryConfigured && !errorId) {
      BarcodePicker.create(divElement!, {
        playSoundOnScan: true,
        vibrateOnScan: true,
        guiStyle: BarcodePicker.GuiStyle.NONE,
        videoFit: BarcodePicker.ObjectFit.COVER,
      })
        .then((barcodePicker) => {
          setBarcodePicker(barcodePicker);
          setBarcodePickerCreated(true);
          const scanSettings = new ScanSettings({
            enabledSymbologies: [
              Barcode.Symbology.EAN8,
              Barcode.Symbology.EAN13,
              Barcode.Symbology.UPCA,
              Barcode.Symbology.UPCE,
              Barcode.Symbology.CODE128,
              Barcode.Symbology.CODE39,
              Barcode.Symbology.CODE93,
              Barcode.Symbology.INTERLEAVED_2_OF_5,
            ],
            codeDuplicateFilter: 1000, // Minimum delay between duplicate results
          });
          scanSettings.setSearchArea({
            x: 0.1,
            y: 0.2,
            width: 0.8,
            height: 0.6,
          });
          barcodePicker.setViewfinderArea({
            x: 0.1,
            y: 0.4,
            width: 0.8,
            height: 0.2,
          });
          barcodePicker.applyScanSettings(scanSettings);
          barcodePicker.setTargetScanningFPS(10);
          if (!showScanner) barcodePicker.pauseScanning(true);
          if (showScanner) barcodePicker.resumeScanning();
          barcodePicker.on("scan", (scanResult) => {
            scanItem(scanResult.barcodes[0].data);
            if (shop?.pauseScanner) {
              barcodePicker.pauseScanning(true);
              setTimeout(() => {
                barcodePicker.resumeScanning();
              }, shop?.pauseScannerInMs);
            }
          });
          barcodePicker.on("scanError", function (error: any) {
            setErrorMessageForLog(error?.response?.data);
            setErrorId(ErrorMessagesId.scan_cannot_be_used);
          });
        })
        .catch(() => {
          // LogEvents.logEvent(ErrorMessagesId.no_access_to_camera, "accessToCameraError", "ERROR");
          setErrorId(ErrorMessagesId.no_access_to_camera);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [libraryConfigured, divElement, showScanner, errorId]);

  useEffect(() => {
    if (error) {
      setShowErrorPopup(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  useEffect(() => {
    const videos = document.querySelectorAll('body > video');

    videos.forEach(video => {
      if (video.nodeType === 1) {
        video.remove();
      }
    });

    if (errorId && barcodePicker) {
      barcodePicker.destroy();
    }
  }, [errorId]);

  return (
    <>
      {showScanner && (
        <div className="scandit-container">
          <div id="scandit-barcode-picker" className="scandit_window"></div>
          {showErrorPopup && <ErrorPopup />}
        </div>
      )}
    </>
  );
};

export default Scandit;
