import React, {useContext, useEffect, useState} from "react";
import {Button, ExpandableButton} from "../../components";
import send from "../../assets/icons/send_grey.png";
import {useTranslation} from "react-i18next";
import SupportFormService, {SupportFormData} from "../../services/SupportFormService";
import SupportPagePopup from "../../components/popups/SupportPagePopup/SupportPagePopup";
import {useHistory} from "react-router-dom";
import close from "../../assets/icons/white_close_icon.png";
import {ShopContext} from "../../context";
import i18n from "i18next";

const SupportFormPage: React.FC = () => {
    const { t } = useTranslation();
    const history = useHistory();
    const [showSupportFormModal, setShowSupportFormModal] = useState(false);
    const [supportFormModalText, setSupportFormModalText] = useState('');

    const { shopData } = useContext(ShopContext);

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [comment, setComment] = useState('');
    const [shopDescription, setShopDescription] = useState('');

    const shopDataLocale = JSON.parse(localStorage.getItem('shopData')!);

    const submitForm = async() => {
        const isFormValid = validateForm();
        if (!isFormValid) return;

        const response = await SupportFormService.sendData(getData());

        setSupportFormModalText(response);
        setShowSupportFormModal(true);
    }

    const validateForm = (): boolean => {
        if (firstName.trim().length === 0) return false;
        if (lastName.trim().length === 0) return false;
        if (email.trim().length === 0) return false;
        if (comment.trim().length === 0) return false;
        if (shopDataLocale && shopDataLocale.shopId === '0' && shopDescription.trim().length === 0) return false;

        return true;
    }

    const getData = (): SupportFormData => {
        return {
            firstName: firstName,
            lastName: lastName,
            eMail: email,
            comment: comment,
            shopDescription: shopDescription
        }
    }

    const updateFirstName = (event: any) => {
        setFirstName(event.target.value);
    }

    const updateLastName = (event: any) => {
        setLastName(event.target.value);
    }

    const updateEmail = (event: any) => {
        setEmail(event.target.value);
    }

    const updateComment = (event: any) => {
        setComment(event.target.value);
    }

    const updateShopDescription = (event: any) => {
        setShopDescription(event.target.value);
    }

    const redirectUser = () => {
        setShowSupportFormModal(false);
        history.goBack();
    }

    useEffect(() => {
        i18n.changeLanguage(shopDataLocale.currentLanguage.toUpperCase());
    }, [shopData])

    return (
        <div className="support-form success">
            <SupportPagePopup
                setShowPopup={setShowSupportFormModal}
                showPopup={showSupportFormModal}
                onClosePopup={redirectUser}
                text={supportFormModalText}
            />
            <div className="support-form__lang_dropdown_button">
                <ExpandableButton type="green" invalidShopID />
            </div>

            <div className="card">
                <img
                    src={close}
                    alt="Close modal"
                    className="error_popup__close"
                    onClick={redirectUser}
                />
                <div className="title">
                    {t("SUPPORT.TITLE")}
                </div>

                <div className="content">
                    <div className='form-group'>
                        <label>{t("SUPPORT.FIRST_NAME")}</label> <br/>
                        <input value={firstName} type="text" onInput={updateFirstName}/>
                    </div>
                    <div className='form-group'>
                        <label>{t("SUPPORT.LAST_NAME")}</label> <br/>
                        <input type="text" value={lastName} onInput={updateLastName}/>
                    </div>
                    <div className='form-group'>
                        <label>{t("SUPPORT.EMAIL")}</label> <br/>
                        <input type="email" value={email} onInput={updateEmail}/>
                    </div>
                    <div className='form-group'>
                        <label>{t("SUPPORT.COMMENT")}</label> <br/>
                        <textarea value={comment} onInput={updateComment} rows={3}></textarea>
                    </div>
                    {shopDataLocale && shopDataLocale.shopId === '0' &&
                        <div className='form-group'>
                            <label>{t("SUPPORT.SHOP_DESCRIPTION")}</label> <br/>
                            <textarea value={shopDescription} onInput={updateShopDescription} rows={3}></textarea>
                        </div>
                    }
                </div>
                <Button
                    type="white"
                    rightIcon={send}
                    clickHandler={submitForm}
                    text={t("SUPPORT.SUBMIT_TEXT")}
                />
            </div>
        </div>
    );
};

export default SupportFormPage;
