import axios from "axios";
import config from "../config/config";
const { backendUrl } = config;

export interface SupportFormData {
    firstName: string;
    lastName: string;
    eMail: string;
    comment: string;
    shopDescription: string;
}

class SupportFormService {
    androidOrIOS() {
        const userAgent = navigator.userAgent;
        if(/android/i.test(userAgent)){
            return 'android';
        } else if(/iPad/i.test(userAgent)){
            return 'iPad';
        }else if(/iPhone/i.test(userAgent)){
            return 'iphone';
        }
    }

    browserVersion() {
        var sBrowser, sUsrAg = navigator.userAgent;

        if (sUsrAg.indexOf("Firefox") > -1) {
            sBrowser = "Mozilla Firefox";
        } else if (sUsrAg.indexOf("SamsungBrowser") > -1) {
            sBrowser = "Samsung Internet";
        } else if (sUsrAg.indexOf("Opera") > -1 || sUsrAg.indexOf("OPR") > -1) {
            sBrowser = "Opera";
        } else if (sUsrAg.indexOf("Trident") > -1) {
            sBrowser = "Microsoft Internet Explorer";
        } else if (sUsrAg.indexOf("Edge") > -1) {
            sBrowser = "Microsoft Edge";
        } else if (sUsrAg.indexOf("Chrome") > -1) {
            sBrowser = "Google Chrome or Chromium";
        } else if (sUsrAg.indexOf("Safari") > -1) {
            sBrowser = "Apple Safari";
        } else {
            sBrowser = "unknown";
        }

        return sBrowser;
    }

    async sendData(data: SupportFormData): Promise<any> {
        const shopData = JSON.parse(localStorage.getItem('shopData')!);

        const browser = this.browserVersion();
        let device = this.androidOrIOS();
        const sessionId = localStorage.getItem("sessionId");

        if (!device) {
            device = "Not Mobile";
        }

        const payload = {
            shopId: shopData.shopId !== '0' ? shopData.shopId : null,
            language: shopData.currentLanguage,
            sessionId,
            device,
            browser,
            ...data
        };

        return axios
            .post(`${backendUrl}/sendContactForm`, payload, {
                auth:{
                    username: config.backendAuth.username,
                    password: config.backendAuth.password
                }
            }).then((response) => {
                return response.data;
            })
            .catch(e => {
                console.log(e);
            })
    }
}

export default new SupportFormService();
