import { FC, useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";

import {ShopContext} from "../../context";

import { Button, UnlockAnimation, Navbar } from "../../components/";

import basket from "../../assets/icons/green_basket_icon.png";

import Store from "../../store/store";
import {ErrorMessagesId} from "../../context/types";
import LogEvents from "../../services/logEvents";

const LeaveStore: FC = () => {
  const {
    exitShop,
    shop,
    shopData,
    showRedCircle,
    setShowRedCircle,
    isButtonClicked,
    setIsButtonClicked,
    shopMessages
  } = useContext(ShopContext);

  const history = useHistory();

  const outOfProximity = Store.state.outOfProximityError;

  const isOutOfProximity = () => {
    if (outOfProximity) {
      return true;
    }
    return false;
  };

  const alreadyInStoreButtonClickHandler = () => {
    Store.state.outOfProximityError = "";
    history.push(`/${shopData.shopId}?lang=${shopData.currentLanguage}`);
  };

  useEffect(() => {
    exitShop();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isOutOfProximity()) {
      LogEvents.logEvent(ErrorMessagesId.out_of_proximity, "User is not in shop proximity", "ERROR");
    }
  }, [shop.userOutOfShopRangeText, outOfProximity, showRedCircle]);

  useEffect(() => {
    const localBtnSwipe = localStorage.getItem("isButtonSwiped");
    if (localBtnSwipe) {
      localStorage.removeItem("isButtonSwiped");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isButtonClicked === true) {
      localStorage.setItem("isButtonSwipedExit", JSON.stringify(true));
    }
  }, [isButtonClicked]);

  return (
    <div className="leave_store">
      <Navbar
        setShowRedCircle={setShowRedCircle}
        setIsButtonClicked={setIsButtonClicked}
      />
      <div className="leave_store__content">
        <div className="leave_store__welcome_message">
          <p className="leave_store__title">{shopMessages?.LEAVE_STORE_PAGE?.THANK_YOU_SCREEN_TITLE}</p>
          <p className="leave_store__subtitle">
            {shopMessages?.LEAVE_STORE_PAGE?.THANK_YOU_SCREEN_SUBTITLE}
          </p>
        </div>
        <div>
          <UnlockAnimation
            showRedCircle={showRedCircle}
            setShowRedCircle={setShowRedCircle}
            isButtonClicked={isButtonClicked}
            setIsButtonClicked={setIsButtonClicked}
            leaveStore
            outOfProximity={isOutOfProximity()}
          />
        </div>
        {!outOfProximity && (
          <div className="leave_store__bottom">
            <div className="leave_store__button">
              <Button
                type="white"
                rightIcon={basket}
                text={shopMessages?.LEAVE_STORE_PAGE?.SHOP_AGAIN}
                clickHandler={alreadyInStoreButtonClickHandler}
                loggingId="shop again"
              />
            </div>
            <ul className="leave_store__links">
              {shop?.messages?.SHOP_MENU?.map((item, index) => {
                return (
                  <li key={index} className="leave_store__link">
                    <a href={item.link} rel="noreferrer" target="_blank">
                      {item.label}
                    </a>
                  </li>
                );
              })}
            </ul>
          </div>
        )}
      </div>
    </div>
  );
};

export default LeaveStore;
