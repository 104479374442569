import React, { useContext, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { CreatePaymentTokenData, InfoMessageId, PaymentTokenDetails, RegisterTokenData } from "../../context/types";
import { PaymentTokenizationContext, ShopContext } from "../../context";
import { Button, ExpandableButton } from "../../components";
import check from "../../assets/icons/green_check_circle_icon.png";
import LogEvents from "../../services/logEvents";

const SuccessPage: React.FC = () => {
    let params: { transactionId: string } = useParams();
    const { createToken } = useContext(PaymentTokenizationContext)
    const history = useHistory();
    const shopDataLocal = JSON.parse(localStorage.getItem("shopData")!);
    const { getShopInfo, shopMessages } = useContext(ShopContext);

    const [text, setText] = useState("");
    const [title, setTitle] = useState("");

    useEffect(() => {
        const shopInfoLocale = JSON.parse(localStorage.getItem('shopInfo')!);

        const token = getTokenFromLocalStorage();
        if (token) return;
        setupPaymentTokenizationToken();

        if (shopInfoLocale) return;

        getShopInfo(shopDataLocal)
            .then((shopInfo: any) => {
                setText(shopInfo.messages.TOKENIZATION.PAYMENT_TOKENIZATION_CONFIRMATIONTEXT);
                setTitle(shopInfo.messages.TOKENIZATION.PAYMENT_TOKENIZATION_CONFIRMATIONTITLE);

                const token = getTokenFromLocalStorage();
                if (token) return;
                setupPaymentTokenizationToken();
            })
    }, []);

    useEffect(() => {
        setText(shopMessages?.TOKENIZATION?.PAYMENT_TOKENIZATION_CONFIRMATIONTEXT);
        setTitle(shopMessages?.TOKENIZATION?.PAYMENT_TOKENIZATION_CONFIRMATIONTITLE);
    }, [shopMessages]);

    const getTokenFromLocalStorage = () => {
        let paymentTokenDetailsList: PaymentTokenDetails[] = JSON.parse(localStorage.getItem("paymentTokenDetailsList") || '[]');

        const tokenDetails = paymentTokenDetailsList.filter(tokenDetails => tokenDetails.shopId === shopDataLocal.shopId);
        return tokenDetails[0]?.paymentToken;
    }

    const setTokenInLocalStorage = (token: string) => {
        const shopData = JSON.parse(localStorage.getItem('shopData')!);

        let paymentTokenDetailsList: PaymentTokenDetails[] = JSON.parse(localStorage.getItem("paymentTokenDetailsList") || '[]');

        const data: PaymentTokenDetails = {
            shopId: shopData.shopId,
            paymentToken: token
        }

        paymentTokenDetailsList.push(data)

        localStorage.setItem("paymentTokenDetailsList", JSON.stringify(paymentTokenDetailsList));
    }

    const setupPaymentTokenizationToken = () => {
        const transactionId = params.transactionId;

        const data: CreatePaymentTokenData = {
            transactionId: transactionId,
            shopId: shopDataLocal.shopId,
        }

        createToken(data).then((token: string) => {
            setTokenInLocalStorage(token);
        }).catch((error: any) => {
            history.push("/enter");
        });
    }

    const redirectUserToShop = () => {
        history.push("/enter")
    }


    return (
        <div className="payment-tokenization success">
            <div className="payment-tokenization__lang_dropdown_button">
                <ExpandableButton type="green" />
            </div>

            <div className="card">
                <div className="title"
                    dangerouslySetInnerHTML={{ __html: title }}
                >
                </div>

                <div className="content"
                    dangerouslySetInnerHTML={{ __html: text }}
                ></div>
                <Button
                    type="white"
                    rightIcon={check}
                    clickHandler={() => redirectUserToShop()}
                    text={shopMessages?.TOKENIZATION?.SUCCESS?.BUTTON}
                />
            </div>
        </div>
    );
};

export default SuccessPage;
