import { FC, useState, useContext } from "react";
import { useTranslation } from "react-i18next";
import { TransitionGroup, CSSTransition } from "react-transition-group";

import whiteArrowDown from "../../assets/icons/white_arrow_down.png";
import greenArrowDown from "../../assets/icons/green_arrow_down.png";
import whiteArrowUp from "../../assets/icons/white_arrow_up.png";
import greenArrowUp from "../../assets/icons/green_arrow_up.png";
import whiteLanguageIcon from "../../assets/icons/white_language_icon.png";
import greenLanguageIcon from "../../assets/icons/green_language_icon.png";
import { ShopContext } from "../../context";
import LogEvents from "../../services/logEvents";
import {DebugMessageId} from "../../context/types";

type ButtonType = "white" | "green";

interface ButtonProps {
  type: ButtonType;
  disabled?: boolean;
  invalidShopID?: boolean;
  setShowSidebar?(data: boolean): void;
  setShowHeader?(data: boolean): void;
}

const ExpandableButton: FC<ButtonProps> = (props) => {
  const { type, disabled, invalidShopID, setShowSidebar, setShowHeader } =
    props;

  const { i18n, t } = useTranslation();

  const { changeGlobalLanguage, shop, shopData, setShopData, getShopInfo } =
      useContext(ShopContext);

  const [expandButton, setExpandButton] = useState(false);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [availableLanguages, setAvailableLanguages] = useState([
    "en",
    "de",
    "it",
    "fr",
  ]);

  const buttonStyles = () => {
    if (type === "white" && !disabled) {
      return "expandable_button";
    } else if (type === "white" && disabled) {
      return "expandable_button--disabled";
    } else if (type === "green" && !disabled) {
      return "green_expandable_button";
    } else if (type === "green" && disabled) {
      return "green_expandable_button--disabled";
    }
  };

  const styles = expandButton ? `${buttonStyles()}--expanded` : buttonStyles();

  const onClick = () => {
    if (!disabled) setExpandButton(!expandButton);
  };

  const setLanguage = (clickedLanguage: string) => {
    i18n.changeLanguage(clickedLanguage.toUpperCase());

    const shopDataLocal = JSON.parse(localStorage.getItem('shopData')!);

    if (shopDataLocal) {
      shopDataLocal.currentLanguage = clickedLanguage.toLowerCase();
      localStorage.setItem('shopData', JSON.stringify(shopDataLocal));
      setShopData(shopDataLocal);
    }
  }

  const qrLanguageClickHandler = (clickedLanguage: any) => {
    setLanguage(clickedLanguage);
    setExpandButton(false);
    logChangingLanguage(clickedLanguage);
    if (setShowSidebar) {
      setShowSidebar(false);
    }
    if (setShowHeader) {
      setShowHeader(true);
    }
  };

  const languageClickHandler = async(clickedLanguage: any) => {
    setLanguage(clickedLanguage);
    logChangingLanguage(clickedLanguage.toLowerCase());

    changeGlobalLanguage(clickedLanguage.toLowerCase()); //Language logic function
    setExpandButton(false);
    if (setShowSidebar) {
      setShowSidebar(false);
    }
    if (setShowHeader) {
      setShowHeader(true);
    }
  };

  const logChangingLanguage = (language: string) => {
    LogEvents.logEvent(DebugMessageId.language_switch, `Switched language to ${language}`, "DEBUG");
  }

  const currentLanguage = shop.availableLanguages?.find(
    (currentLanguage: AvailableLanguagesType) =>
      currentLanguage.code === shopData?.currentLanguage?.toUpperCase()
  );

  const qrDisplayLanguage = () => {
    const shopDataLocal = JSON.parse(localStorage.getItem('shopData')!);
    if (!shopDataLocal) return;

    switch (shopDataLocal.currentLanguage) {
      case "en":
        return `${t("LANGUAGES.ENGLISH")}`;
      case 'de':
        return `${t("LANGUAGES.GERMAN")}`;
      case 'it':
        return  `${t("LANGUAGES.ITALIAN")}`;
      case 'fr':
        return  `${t("LANGUAGES.FRENCH")}`;
      default:
        return `${t("LANGUAGES.ENGLISH")}`;
    }
  };

  return (
    <div className={styles}>
      <div className="expandable_button__head" onClick={onClick}>
        <img
          className="expandable_button__left_icon"
          alt="left"
          src={type === "white" ? greenLanguageIcon : whiteLanguageIcon}
        />
        <p>{invalidShopID ? qrDisplayLanguage() : currentLanguage?.extended}</p>
        {!expandButton && (
          <img
            className="expandable_button__right_icon"
            alt="right"
            src={type === "white" ? greenArrowDown : whiteArrowDown}
          />
        )}
        {expandButton && (
          <img
            className="expandable_button__right_icon"
            alt="right"
            src={type === "white" ? greenArrowUp : whiteArrowUp}
          />
        )}
      </div>
      <TransitionGroup component={null}>
        {expandButton && (
          <CSSTransition timeout={500} classNames="expand">
            <div className={`${buttonStyles()}__expand`}>
              <ul>
                {invalidShopID && (
                  <>
                    {availableLanguages.map((language) => {
                      const displayLanguage = () => {
                        switch (language) {
                          case "en":
                            return `${t("LANGUAGES.ENGLISH")}`;
                          case 'de':
                            return `${t("LANGUAGES.GERMAN")}`;
                          case 'it':
                            return  `${t("LANGUAGES.ITALIAN")}`;
                          case 'fr':
                            return  `${t("LANGUAGES.FRENCH")}`;
                        }
                      };
                      return (
                        <li
                          key={language}
                          onClick={() => qrLanguageClickHandler(language)}
                        >
                          {displayLanguage()}
                        </li>
                      );
                    })}
                  </>
                )}
                {!invalidShopID && (
                  <>
                    {shop.availableLanguages.map((language) => {
                      return (
                        <li
                          key={language.code}
                          onClick={() => languageClickHandler(language.code)}
                        >
                          {language.extended}
                        </li>
                      );
                    })}
                  </>
                )}
              </ul>
            </div>
          </CSSTransition>
        )}
      </TransitionGroup>
    </div>
  );
};

export default ExpandableButton;
