import { FC, useContext, useState } from "react";
import { Redirect } from "react-router-dom";
import QrReader from "react-qr-reader";

import {NewErrorContext} from "../../context";

import "./QrScanner.scss";

import LogEvents from "../../services/logEvents";
import {ErrorMessagesId} from "../../context/types";

const QrScanner: FC = () => {
  const { setErrorId } = useContext(NewErrorContext);

  const [url, setUrl] = useState("");

  const handleScan = (data: string | null) => {
    if (data) {
      setUrl(`${data.split("/").slice(-1).toString()}`);
    }
  };

  const handleError = (err: string) => {
    // LogEvents.logEvent(ErrorMessagesId.no_access_to_camera, "Camera blocked", "ERROR")
    setErrorId(ErrorMessagesId.no_access_to_camera);
  };

  return (
    <div className="qr_scanner">
      <div>
        <QrReader
          delay={300}
          onError={handleError}
          onScan={handleScan}
          className="qr_scanner__window"
        />
      </div>
      {url !== "" && <Redirect to={url} />}
    </div>
  );
};

export default QrScanner;
