import { FC } from "react";

type Props = {
  item: CartItemType;
  type?: string;
};

const CartItem: FC<Props> = (props) => {
  const { item, type } = props;

  const shopInfo = JSON.parse(localStorage.getItem("shopInfo")!);

  const itemDescription = function () {
    if (isItemWithSpecialWeightBarcode()) {
      return formatDescriptionForSpecialWeightBarcode();
    }

    if (isItemWithSpecialPriceBarcode()) {
      return formatDescriptionForSpecialPriceBarcode();
    }

    return formatDescriptionForBasicItem();
  };

  const formatDescriptionForSpecialWeightBarcode = () => {
    return item.amount + " x " + item.weight + "kg x " + item.price.toFixed(2) + `${shopInfo.currency}/kg`;
  }

  const formatDescriptionForSpecialPriceBarcode = () => {
    return item.amount + " x " + item.price.toFixed(2) + `${shopInfo.currency}`;
  }

  const formatDescriptionForBasicItem = () => {
    return item.amount + " x " + item.subtitle;
  }

  const isItemWithSpecialWeightBarcode = (): boolean => {
    let firstTwoNumbers = item.barcode.slice(0, 2);

    return firstTwoNumbers === "23" ||
      firstTwoNumbers === "28" ||
      firstTwoNumbers === "29"
  }

  const isItemWithSpecialPriceBarcode = (): boolean => {
    let firstTwoNumbers = item.barcode.slice(0, 2);

    return firstTwoNumbers === "21" ||
      firstTwoNumbers === "22" ||
      firstTwoNumbers === "24"
  }

  const cartItemStyles =
    type === "receipt" ? "cart_item--receipt" : "cart_item";

  return (
    <div className={cartItemStyles}>
      <div className="cart_item__item_name">{item.title}</div>
      <div className="cart_item__weight_price">
        <div className="cart_item__weight">
          {itemDescription()}
        </div>
        <div className="cart_item__price">
          {shopInfo?.currency} {(item.amount * item.priceToDisplay).toFixed(2)}
        </div>
      </div>
    </div>
  );
};

export default CartItem;
