import React, { useContext } from "react";

import { ShopContext } from "../../context";

import { ShopComp, Navbar } from "../../components";

const Shop: React.FC = () => {
  const { setShowRedCircle, setIsButtonClicked } = useContext(ShopContext);

  return (
    <div className="shop_page">
      <Navbar
        setShowRedCircle={setShowRedCircle}
        setIsButtonClicked={setIsButtonClicked}
      />
      <ShopComp />
    </div>
  );
};

export default Shop;
