import React, {useContext, useEffect, useState} from "react";
import { Button, ExpandableButton } from "../../components";
import check from "../../assets/icons/white_check_circle_icon.png";
import { useHistory } from "react-router-dom";
import {ShopContext} from "../../context";
import LogEvents from "../../services/logEvents";
import {DebugMessageId} from "../../context/types";

const FailedPage: React.FC = (props) => {
  const history = useHistory();
  const { shop } = useContext(ShopContext);

  const [text, setText] = useState("");

  const enterTheStore = () => {
    LogEvents.logEvent(DebugMessageId.t_and_c_accepted, 'T&C accepted', "DEBUG");
    localStorage.setItem("Terms", JSON.stringify(true));
    history.push("/enter");
  };

  useEffect(() => {
    setText(shop.messages?.POPUPS?.GENERAL_POPUP.TERMS_AND_CONDITIONS_TEXT);
  }, [shop]);

  return (
    <div className="terms-and-conditions">
      <div className="terms-and-conditions__lang_dropdown_button">
        <ExpandableButton type="green" />
      </div>
      <div className="card">
        <div className="title">
          <p className="title">{shop.messages?.POPUPS?.GENERAL_POPUP?.TERMS_TITLE}</p>
        </div>
        <div className="content">
          <div
              dangerouslySetInnerHTML={{ __html: text }}
          >
          </div>
          <Button
              type="green"
              rightIcon={check}
              clickHandler={() => enterTheStore()}
              text={shop.messages?.POPUPS?.GENERAL_POPUP?.BUTTON_TEXT}
          />
        </div>
      </div>
    </div>
  );
};

export default FailedPage;
