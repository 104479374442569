import React, { createContext, useContext, useEffect, useState } from "react";

import { UserContext } from "./UserContext";
import { AxiosContext } from "./AxiosContext";
import { ErrorContext } from "./ErrorContext";

import compareUserLocationWithShop from "../utils/compareUserLocationWithShop";

import { checkTime } from "../services/SessonService";
import { ErrorMessagesId, IErrorMessages, PaymentData } from "./types";

import store from "../store/store";
import LogEvents from "../services/logEvents";
import { NewErrorContext } from "./NewErrorContext";

interface IShopContext {
  shop: IShop;
  setShop: (data: any) => void;
  shopData: IShopData;
  setShopData: (data: any) => void;
  changeGlobalLanguage: (lang: string) => void;
  canUserEnterTheShop: () => boolean;
  getGeolocation: (successCallback: any, errorCallback: any) => void;
  letUserInShop: () => void;
  getShopInfo: (shopData: any) => any;
  getShopInventory: (id: string, lang: string) => void;
  openDoor: (openDoorIdAndDirection: IopenDoorIdAndDirection) => any;
  openDoorData: IOpenDoorData;
  setOpenDoorData: (data: IOpenDoorData) => void;
  setIsUnlockStoreAvailable: (val: boolean) => void;
  isUnlockStoreAvailable: boolean;
  showTermsPopup: boolean;
  setShowTermsPopup: (val: boolean) => void;
  setTermsCloseButton: (val: boolean) => void;
  termsCloseButton: boolean;
  exitShop: () => void;
  pay: (paymentData: PaymentData) => any;
  userLocation: LocationCordinates;
  setUserLocation: (lat: any, lon: any) => void;
  logOpenDoor: boolean;
  setLogOpenDoor(val: boolean): void;
  logAlreadyInStore: boolean;
  setLogAlreadyInStore(val: boolean): void;
  showRedCircle: boolean;
  setShowRedCircle(val: boolean): void;
  isButtonClicked: boolean;
  setIsButtonClicked(val: boolean): void;
  shopMessages: IShopMessages;
  setShopMessages(messages: IShopMessages): void;
  shopAvailableLanguages: AvailableLanguagesType[],
  setShopAvailableLanguages(languages: AvailableLanguagesType[]): void;
  shopErrorMessages: IErrorMessages;
  setShopErrorMessages(errorMessages: IErrorMessages): void;
}

const ShopContext = createContext({} as IShopContext);

interface IShopContextProps {
  children: React.ReactNode;
}

const ShopContextProvider: React.FC<IShopContextProps> = (props) => {
  const { guestUser, setGuestUser } = useContext(UserContext);
  const { axiosInstance, setPaymentLinkClicked } =
    useContext(AxiosContext);
  const { setErrorId, setErrorMessageForLog } = useContext(NewErrorContext);

  const [logOpenDoor, setLogOpenDoor] = useState(false);
  const [logAlreadyInStore, setLogAlreadyInStore] = useState(false);
  const [showRedCircle, setShowRedCircle] = useState(false);
  const [isButtonClicked, setIsButtonClicked] = useState(false);
  const [showErrorOnLanguageChange, setShowErrorOnLanguageChange] =
    useState(false);
  const [showTermsPopup, setShowTermsPopup] = useState(false);
  const [isUnlockStoreAvailable, setIsUnlockStoreAvailable] = useState(true);
  const [termsCloseButton, setTermsCloseButton] = useState(false);
  const [shopData, setShopData] = useState<IShopData>({} as IShopData);
  const [shop, setShop] = useState<IShop>({} as IShop);
  const [openDoorData, setOpenDoorData] = useState<IOpenDoorData>(
    {} as IOpenDoorData
  );
  const [shopMessages, setShopMessages] = useState<IShopMessages>({} as IShopMessages);
  const [shopAvailableLanguages, setShopAvailableLanguages] = useState<AvailableLanguagesType[]>([
    { code: "EN", extended: "English" },
    { code: "DE", extended: "Deutsch" },
    { code: "FR", extended: "Français" },
    { code: "IT", extended: "Italiano" }
  ]);
  const [shopErrorMessages, setShopErrorMessages] = useState({} as IErrorMessages);

  const [userLocation, setUserLocation] = useState<LocationCordinates>(
    {} as LocationCordinates
  );

  const doesLanguageBelongToShopLanguages = (languageCode: string): boolean => {
    return shopAvailableLanguages.find(language => language.code.toLowerCase() === languageCode.toLowerCase()) !== undefined;
  }

  const getShopInfo = (shopData: any) => {
    let url = `/getShopData/${shopData?.shopId}?lang=${shopData?.currentLanguage}`;
    if (shopData && shopData.currentLanguage && !doesLanguageBelongToShopLanguages(shopData?.currentLanguage)) {
      url = `/getShopData/${shopData?.shopId}`;
    }

    return axiosInstance({
      method: "GET",
      url
    })
      .then((response: any) => {
        localStorage.setItem('shopInfo', (JSON.stringify(response.data)));
        setShop({
          ...response.data,
          id: shopData.shopId
        });
        store.state.shop = response.data;
        setShopMessages(response.data.messages);
        setShopAvailableLanguages(response.data.availableLanguages);
        setShopErrorMessages(response.data.errorMessages);
        return response.data;
      })
      .catch((error: any) => {
        if (error.response.data.code === 1) {
          LogEvents.logEvent(ErrorMessagesId.shop_id_can_not_be_found, error.response.data.message, "ERROR");
          throw error;
        }
        switch (error.response.status) {
          case 401:
            LogEvents.logEvent(ErrorMessagesId.authentication_issue, error.response.data.message, "ERROR");
            break;
          case 400:
            LogEvents.logEvent(ErrorMessagesId.bad_request_issue, error.response.data.message, "ERROR");
            break;
          case 500:
            LogEvents.logEvent(ErrorMessagesId.server_issue, error.response.data, "ERROR");
        }
        // LogEvents.logEvents("errorOccurred", error.response);
        throw error;
      });
  };

  const getShopInventory = (id: string, lang: string) => {
    let url = `/getShopInventory/${id}?lang=${lang}`;
    if (!doesLanguageBelongToShopLanguages(lang)) {
      url = `/getShopInventory/${id}`;
    }

    return axiosInstance({
      method: "GET",
      url
    })
      .then((response: any) => {
        const shopData = JSON.parse(localStorage.getItem('shopData')!);
        shopData.inventory = response.data;
        localStorage.setItem('shopData', JSON.stringify(shopData));

        return response.data;
      })
      .catch((error: any) => {
        if (error.response.status === 500) {
          setErrorId(ErrorMessagesId.server_issue);
        }
      });
  };

  const setShopInventory = (inventory: CartItemType[]) => {
    setShopData({
      ...shopData,
      inventory,
    });
  };

  const openDoor = (openDoorIdAndDirection: IopenDoorIdAndDirection) => {
    const shopDataLocale = JSON.parse(localStorage.getItem('shopData')!);
    const language = shopDataLocale.currentLanguage;
    return axiosInstance({
      method: "POST",
      url: `/openDoor/${openDoorIdAndDirection.id}?lang=${language}`,
      data: openDoorIdAndDirection.direction,
    })
      .then((response: any) => {
        setOpenDoorData(response.data);
        return response;
      })
      .catch((err: any) => {
        if (err.response.status === 500) {
          setErrorId(ErrorMessagesId.connectivity_issue);
        }
        throw err.response;
      });
  };

  const pay = (paymentData: PaymentData) => {
    return axiosInstance({
      method: "POST",
      url: `/create-payment-url/`,
      data: paymentData,
    })
      .then((response: any) => {
        window.location.href = `${response.data}`;
        setPaymentLinkClicked(false);
        return response.data;
      })
      .catch((err: any) => {
        if (err.response.status === 500) {
          setErrorMessageForLog(err.response.data);
          setErrorId(ErrorMessagesId.psp_not_reachable);
        }
        if (err.response.status === 422) {
          setErrorMessageForLog(err.response.data);
          setErrorId(ErrorMessagesId.payment_error);
        }
        LogEvents.logEvent(ErrorMessagesId.any_payment_issue, err.response.data, "ERROR");
      });
  };

  const changeGlobalLanguage = (lang: string) => {
    getShopInventory(shopData?.shopId, lang).then(
      (inventory: CartItemType[]) => {
        const shopDataPlaceholder = {
          ...shopData,
          currentLanguage: lang,
          inventory,
        };
        setShopData(shopDataPlaceholder);
        localStorage.setItem("shopData", JSON.stringify(shopDataPlaceholder));
        if (shopData?.shopId !== "0") {
          setShowErrorOnLanguageChange(true);
          getShopInfo(shopDataPlaceholder);
        }
      }
    ).catch((error: any) => {
      if (error?.response?.status === 500) {
        setErrorId(ErrorMessagesId.server_issue);
      }
    });
  };

  const letUserInShop = () => {
    const shopDataLocale = JSON.parse(localStorage.getItem('shopData')!);
    if (!shopDataLocale.inventory) {
      getShopInventory(shopData?.shopId, shopData?.currentLanguage).then(
        (response: CartItemType[]) => {
          setShopInventory(response);
        }
      );
    }
    const sessionStart = new Date().getTime();
    const guestUser = {
      shopId: shopData?.shopId,
      shoppingCart: [],
      sessionStart: sessionStart,
    };
    setGuestUser(guestUser);
    localStorage.setItem("guestUser", JSON.stringify(guestUser));
  };

  const exitShop = () => {
    if (guestUser) {
      localStorage.removeItem("guestUser");
      localStorage.removeItem("Terms");
      localStorage.removeItem("userLocation");
      localStorage.removeItem("shopInfo");
      localStorage.removeItem("isAnimationPlayed");

      // for not losing currentLanguage as per requirement
      const shopData = JSON.parse(localStorage.getItem('shopData')!);
      shopData.shopId = "0";
      delete shopData.inventory;
      localStorage.setItem('shopData', JSON.stringify(shopData));
    }
  };

  const getGeolocation = (successCallback: any, errorCallback: any) => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position: any) => {
          let locationPlaceholder = {
            lat: position.coords.latitude,
            lon: position.coords.longitude,
          };
          localStorage.setItem(
            "userLocation",
            JSON.stringify(locationPlaceholder)
          );
          successCallback();
        },
        (error) => {
          errorCallback();
        }
      );
    }
  };

  const canUserEnterTheShop = () => {
    if (shop?.locationCheckRequired === false) {
      return true;
    }
    const localLocation = JSON.parse(localStorage.getItem("userLocation")!);
    if (
      shop?.shopCoordinates !== undefined &&
      shop?.shopCoordinates?.lat &&
      localLocation?.lat
    ) {
      return compareUserLocationWithShop(
        localLocation,
        shop?.shopCoordinates,
        shop?.shopProximityRangeInMeters
      );
    }

    return false;
  };

  const setTheme = () => {
    const theme = shop?.shopCustomCss;
    const themePlaceholder = (className: string, apiClassName: any) => {
      document.body.style.setProperty(className, apiClassName);
    };
    if (shop?.shopCustomCss) {
      themePlaceholder("--primaryColorDark", theme.primaryColorDark);
      themePlaceholder("--primaryColor", theme.primaryColor);

      themePlaceholder("--green", theme.green);
      themePlaceholder("--red", theme.red);
      themePlaceholder("--white", theme.white);
      themePlaceholder("--darkRed", theme.darkRed);

      themePlaceholder("--doorColorLight", theme.doorColorLight);
      themePlaceholder("--doorColor", theme.doorColor);
      themePlaceholder("--doorColorLuminous", theme.doorColorLuminous);
    }
  };

  useEffect(() => {
    const shopDataLocal = JSON.parse(localStorage.getItem("shopData")!);
    const inventory = shopDataLocal?.inventory;

    if (inventory && inventory.length > 0) {
      setShopData({
        shopId: shopDataLocal?.shopId,
        currentLanguage: shopDataLocal?.currentLanguage,
        inventory,
      });

      return;
    }

    if (shopData.shopId && shopData.shopId !== '0') {
      getShopInventory(shopDataLocal.shopId, shopDataLocal.currentLanguage).then((inventory: CartItemType[]) => {
        setShopData({
          shopId: shopDataLocal?.shopId,
          currentLanguage: shopDataLocal?.currentLanguage,
          inventory,
        });
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shopData?.shopId]);

  useEffect(() => {
    const shopInfoLocal = JSON.parse(localStorage.getItem("shopInfo")!);

    if (shopInfoLocal?.shopName && !shop?.shopName) {
      setShop(shopInfoLocal)
    }
  }, [shop?.shopName]);

  useEffect(() => {
    const shopDataLocal = JSON.parse(localStorage.getItem("shopData")!);

    if (shopDataLocal?.shopId && !shopData?.shopId) {
      setShopData(shopDataLocal);
    }
  }, [shopData?.shopId])

  useEffect(() => {
    if (shopData?.shopId && shopData?.shopId !== "0") {
      getShopInfo(shopData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shopData?.shopId]);

  useEffect(() => {
    checkTime();
  }, []);

  useEffect(() => {
    if (shop?.shopCustomCss) setTheme();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shop?.shopCustomCss]);

  const providerValue = {
    shop,
    setShop,
    shopData,
    setShopData,
    changeGlobalLanguage,
    canUserEnterTheShop,
    getShopInfo,
    getShopInventory,
    isUnlockStoreAvailable,
    setIsUnlockStoreAvailable,
    getGeolocation,
    letUserInShop,
    exitShop,
    openDoor,
    openDoorData,
    setOpenDoorData,
    pay,
    setUserLocation,
    userLocation,
    termsCloseButton,
    setTermsCloseButton,
    showTermsPopup,
    setShowTermsPopup,
    logOpenDoor,
    setLogOpenDoor,
    logAlreadyInStore,
    setLogAlreadyInStore,
    showRedCircle,
    setShowRedCircle,
    isButtonClicked,
    setIsButtonClicked,
    shopMessages,
    setShopMessages,
    shopAvailableLanguages,
    setShopAvailableLanguages,
    shopErrorMessages,
    setShopErrorMessages
  };

  return (
    <ShopContext.Provider value={providerValue}>
      {props.children}
    </ShopContext.Provider>
  );
};

export { ShopContextProvider, ShopContext };
