import React, { useContext, useEffect, useState } from "react";
import { Button, ExpandableButton } from "../../components";
import {CreateTokenizationUrlData, PaymentData, RegisterTokenData} from "../../context/types";
import config from "../../config/config";
import {
  AxiosContext,
  PaymentTokenizationContext,
  ShopContext,
} from "../../context";
import check from "../../assets/icons/green_check_circle_icon.png";

const FailedPage: React.FC = () => {
  const { shop, shopMessages } = useContext(ShopContext);
  const { createTokenizationUrl } = useContext(PaymentTokenizationContext);
  const { setDisplayLoader, setPaymentLinkClicked} = useContext(AxiosContext);

  const [text, setText] = useState("");
  const [title, setTitle] = useState("");

  useEffect(() => {
    setText(shopMessages?.TOKENIZATION?.PAYMENT_TOKENIZATION_TEXT);
    setTitle(shopMessages?.TOKENIZATION?.PAYMENT_TOKENIZATION_TITLE);
  }, [shopMessages]);

  // todo: refactor - same method in Failed page!!!
  const acceptInfo = async () => {
    setPaymentLinkClicked(true);
    const shopDataLocal = JSON.parse(localStorage.getItem("shopData")!);
    const createTokenizationUrlData: CreateTokenizationUrlData = {
      shopId: shopDataLocal.shopId,
      language: shopDataLocal.currentLanguage,
      successUrl: config.paymentTokenizationSuccessUrl,
      failedUrl: config.paymentTokenizationFailedUrl,
    }

    setDisplayLoader(true);

    await createTokenizationUrl(createTokenizationUrlData)
      .then((response: string) => {
        window.location.href = response;
      })
  };

  return (
    <div className="payment-tokenization info">
      <div className="payment-tokenization__lang_dropdown_button">
        <ExpandableButton type="green" />
      </div>
      <div className="card">
        <div
          className="title"
          dangerouslySetInnerHTML={{ __html: title }}
        ></div>
        <div
          className="content"
          dangerouslySetInnerHTML={{ __html: text }}
        ></div>
        <Button
          type="white"
          rightIcon={check}
          clickHandler={() => acceptInfo()}
          text={shopMessages?.TOKENIZATION?.INFO?.BUTTON}
        />
      </div>
    </div>
  );
};

export default FailedPage;
