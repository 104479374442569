import React, { FC, createContext, useState } from "react";
interface INewErrorContext {
  setErrorId(data: number|null): void;
  errorId: number|null;
  errorMessageForLog: string|null;
  setErrorMessageForLog(data: string|null): void;
}

const NewErrorContext = createContext({} as INewErrorContext);

interface ErrorContextProps {
  children: React.ReactNode;
}

const NewErrorContextProvider: FC<ErrorContextProps> = (props) => {
  const [errorId, setErrorId] = useState<number|null>(null);
  const [errorMessageForLog, setErrorMessageForLog] = useState<string|null>(null);

  const providerValue = {
    errorId,
    setErrorId,
    errorMessageForLog,
    setErrorMessageForLog
  };

  return (
    <NewErrorContext.Provider value={providerValue}>
      {props.children}
    </NewErrorContext.Provider>
  );
};

export { NewErrorContext, NewErrorContextProvider };
