import React, { useContext, useEffect, useState } from "react";
import "./scss/main.scss";
import {
  Shop,
  UnlockStore,
  LeaveStore,
  Landing,
  ReceiptPage
} from "./pages/index";
import { Switch, Route, Redirect } from "react-router-dom";

import { ErrorPopup, Loader } from "./components";
import { AxiosContext, ShopContext } from "./context";
import NewErrorPopup from "./components/popups/NewErrorPopup/NewErrorPopup";

import ProtectedRoute from "./auth/protected.route";

import UrlAuthentication from "./auth/UrlAuthentication";
import SuccessPage from "./pages/PaymentTokenization/SuccessPage";
import FailedPage from "./pages/PaymentTokenization/FailedPage";
import InfoPage from "./pages/PaymentTokenization/InfoPage";
import TermsAndConditions from "./pages/TermsAndConditions/TermsAndConditions";

import { v4 as uuid } from "uuid";
import ClosedPage from "./pages/Store/ClosedPage";
import MaintenancePage from "./pages/Store/MaintenancePage";
import SupportFormPage from "./pages/SupportForm/SupportFormPage";
import CacheBuster from "react-cache-buster";

import packageJson from "../package.json";

const App: React.FC = () => {
  const { setDisplayLoader } = useContext(AxiosContext);

  const shopDataLocal = JSON.parse(localStorage.getItem("shopData")!);
  const guestUserLocal = JSON.parse(localStorage.getItem("guestUser")!);
  const [shouldRefresh, setShouldRefresh] = useState(false);

  useEffect(() => {
    setDisplayLoader(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const appVersionLocale = localStorage.getItem("appVersion")
    if (appVersionLocale !== packageJson.version) {
      setShouldRefresh(true);
      return;
    }
    setShouldRefresh(false);
  }, [])

  useEffect(() => {
    if (!localStorage.getItem("sessionId")) {
      localStorage.setItem("sessionId", uuid());
      localStorage.setItem("appOpenIndicator", "webAppWithoutShopName");
    }
  })

  return (
    <CacheBuster
      currentVersion={packageJson.version}
      isEnabled={shouldRefresh}
    >
      <div className="app">
        <Loader />
        <ErrorPopup />
        <NewErrorPopup />
        <Switch>
          <Route exact path="/">
            {guestUserLocal !== null && guestUserLocal.shopId ? (
              <Redirect to="/shop" />
            ) : (
              <Redirect to="/enter" />
            )}
          </Route>
          <Route exact path="/terms-and-conditions" component={TermsAndConditions} />
          <Route exact path="/enter">
            <UnlockStore />
          </Route>
          <Route exact path="/qr" component={Landing} />
          <ProtectedRoute path="/shop/:id?" component={Shop} />
          <Route exact path="/exit">
            {shopDataLocal === null ||
              !shopDataLocal.shopId ? (
              <Redirect to="/qr" />
            ) : (
              <LeaveStore />
            )}
          </Route>
          <Route path="/receipt/:id" component={ReceiptPage} />
          <Route path="/payment-tokenization/info" component={InfoPage} />
          <Route path="/payment-tokenization/success/:transactionId" component={SuccessPage} />
          <Route path="/payment-tokenization/failed/:transactionId" component={FailedPage} />
          <Route path="/closed" component={ClosedPage} />
          <Route path="/maintenance" component={MaintenancePage} />
          <Route path="/support-form" component={SupportFormPage} />
          <Route exact path="/:id" component={UrlAuthentication} />
        </Switch>
      </div>
    </CacheBuster>
  );
};

export default App;
