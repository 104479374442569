import React, {FC, useContext} from "react";

import { PopUpTemplate } from "../../../templates";
import i18n from "i18next";
import {ShopContext} from "../../../context";
import whiteLanguageIcon from "../../../assets/icons/white_language_icon.png";
import {Button} from "../../index";
interface SelectLanguagePopupProps {
  setShowPopup(data: boolean): void;
  showPopup: boolean;
  onClosePopup: (shopDataLocal: any) => void;
  availableLanguages: []
}

const SelectLanguagePopup: FC<SelectLanguagePopupProps> = (props) => {
  const { showPopup, availableLanguages, onClosePopup } = props;

  const { setShopData } = useContext(ShopContext);

  const selectLanguage = (code: string) => {
    i18n.changeLanguage(code.toUpperCase());
    const shopDataLocal = setLanguage(code);
    onClosePopup(shopDataLocal);
  }

  const setLanguage = (clickedLanguage: string) => {
    const shopDataLocal = JSON.parse(localStorage.getItem('shopData')!);
    i18n.changeLanguage(clickedLanguage.toUpperCase());
    if (shopDataLocal) {
      shopDataLocal.currentLanguage = clickedLanguage.toLowerCase();
      localStorage.setItem('shopData', JSON.stringify(shopDataLocal));
      setShopData(shopDataLocal);
    }
    return shopDataLocal;
  }

  return (
    <React.Fragment>
      <PopUpTemplate showPopup={showPopup}>
        <div className="select_language_popup">
          <div className="select_language_popup__content">
            <div className="select_language_popup__head">
                  <p className="select_language_popup__title">
                    Select language
                  </p>
            </div>
            <div className='select_language_popup__text'>
              {
                availableLanguages.map((language: any) => {
                  return <Button
                      type="green"
                      rightIcon={whiteLanguageIcon}
                      clickHandler={() => selectLanguage(language.code)}
                      key={language.code}
                      text={language.extended}
                  />
                  })
              }
            </div>
          </div>
        </div>
      </PopUpTemplate>
    </React.Fragment>
  );
};

export default SelectLanguagePopup;
