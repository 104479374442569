import React, { FC, useContext } from "react";

import { ShopContext } from "../../../context";

import { Button } from "../..";
import { PopUpTemplate } from "../../../templates";

import whiteCheckCircle from "../../../assets/icons/white_check_circle_icon.png";
interface GeneralPopupProps {
  setShowPopup(data: boolean): void;
  showPopup: boolean;
  clickHandler(): void;
  onClosePopup?: () => void;
  type: string;
}

const GeneralPopup: FC<GeneralPopupProps> = (props) => {
  const { clickHandler, showPopup, type } = props;

  const { termsCloseButton, shopMessages } = useContext(ShopContext);

  const terms = type === "terms";
  const imprint = type === "imprint";

  const buttonText = terms
    ? termsCloseButton
    ? shopMessages?.POPUPS?.GENERAL_POPUP?.CLOSE_BUTTON_TEXT
    : shopMessages?.POPUPS?.GENERAL_POPUP?.BUTTON_TEXT
    : shopMessages?.POPUPS?.GENERAL_POPUP?.CLOSE_BUTTON_TEXT;

  return (
    <React.Fragment>
      <PopUpTemplate showPopup={showPopup}>
        <div className="general_popup">
          <div className="general_popup__content">
            <div className="general_popup__head">
              {terms && (
                <>
                  <p className="general_popup__title">
                    {shopMessages?.POPUPS?.GENERAL_POPUP?.TERMS_TITLE}
                  </p>
                  <div
                    className="general_popup__text_container"
                    id="termsAndConditionsApiText"
                    dangerouslySetInnerHTML={{ __html: shopMessages?.POPUPS?.GENERAL_POPUP?.TERMS_AND_CONDITIONS_TEXT}}
                  >
                  </div>
                </>
              )}
              {imprint && (
                <>
                  <p className="general_popup__title">
                    {shopMessages?.POPUPS?.GENERAL_POPUP?.IMPRINT_TITLE}
                  </p>
                  <div
                    className="general_popup__text_container"
                    id="imprintAPItext"
                    dangerouslySetInnerHTML={{ __html: shopMessages?.POPUPS?.GENERAL_POPUP?.IMPRINT_TEXT}}
                  >
                  </div>
                </>
              )}
              <Button
                  type="green"
                  rightIcon={whiteCheckCircle}
                  clickHandler={() => clickHandler()}
                  text={buttonText}
              />
            </div>
          </div>
        </div>
      </PopUpTemplate>
    </React.Fragment>
  );
};

export default GeneralPopup;
