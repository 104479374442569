import LogEvents from "../services/logEvents";
import Store from "../store/store";
import {ErrorMessagesId} from "../context/types";

const compareUserLocationWithShop = (
  userLocationCoords: LocationCordinates,
  shopLocationCoords: LocationCordinates,
  proximityInMeters: any
) => {

  if (userLocationCoords.lat && userLocationCoords.lon) {
    const latitudeRadius = transformDegreeToRadius(
      shopLocationCoords.lat - userLocationCoords.lat
    );

    const longitudeRadius = transformDegreeToRadius(
      shopLocationCoords.lon - userLocationCoords.lon
    );

    const distance =
      Math.sin(latitudeRadius / 2) * Math.sin(latitudeRadius / 2) +
      Math.cos(transformDegreeToRadius(userLocationCoords.lat)) *
        Math.cos(transformDegreeToRadius(shopLocationCoords.lat)) *
        Math.sin(longitudeRadius / 2) *
        Math.sin(longitudeRadius / 2);

    const c = 2 * Math.atan2(Math.sqrt(distance), Math.sqrt(1 - distance));

    const radiusOfEarthInKm = 6371;
    const metersInKm = 1000;

    const distanceInMeters = radiusOfEarthInKm * c * metersInKm;

    const allowedDistanceFromShop = proximityInMeters;

    if (allowedDistanceFromShop > distanceInMeters) {
      return true;
    }

    Store.state.outOfProximityError = "outOfProximity";

    return false;
  }

  return false; //there is no geolocation info gathered
};

const transformDegreeToRadius = (degree: number) => {
  return degree * (Math.PI / 180);
};

export default compareUserLocationWithShop;
