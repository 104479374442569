import React, {FC} from "react";
import circleCheckIcon from '../../../assets/icons/green_check_circle_icon.png';

import { PopUpTemplate } from "../../../templates";
import {Button} from "../../index";
import {useTranslation} from "react-i18next";
interface SupportPagePopupProps {
  setShowPopup(data: boolean): void;
  showPopup: boolean;
  text: string;
  onClosePopup: () => void;
}

const SupportPagePopup: FC<SupportPagePopupProps> = (props) => {
  const { t } = useTranslation();
  const { showPopup, onClosePopup, text } = props;

  return (
    <React.Fragment>
      <PopUpTemplate showPopup={showPopup}>
        <div className="support_page_popup">
          <div className="support_page_popup__content">
            <div className="support_page_popup__head">
                  <p className="support_page_popup__title">
                    {t('POPUPS.SUPPORT_POPUP.TITLE')}
                  </p>
            </div>
            <div className='support_page_popup__text'>
              { text }
            </div>
            <div className="support_page_popup__button">
              <Button
                  type="white"
                  rightIcon={circleCheckIcon}
                  clickHandler={onClosePopup}
                  text={t('POPUPS.SUPPORT_POPUP.CLOSE_BUTTON_TEXT')}
              />
            </div>
          </div>
        </div>
      </PopUpTemplate>
    </React.Fragment>
  );
};

export default SupportPagePopup;
