import React, { useState, useContext, useEffect } from "react";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import { useHistory } from "react-router-dom";
import { FaLock, FaLockOpen } from "react-icons/fa";
import SlideButton from "../SlideButton/SlideButton";

import { ShopContext, NewErrorContext } from "../../context";
import LogEvents from "../../services/logEvents";
import { ErrorMessagesId, InfoMessageId } from "../../context/types";
interface UnlockAnimationProps {
  showRedCircle: boolean;
  setShowRedCircle(data: boolean): void;
  isButtonClicked: boolean;
  setIsButtonClicked(data: boolean): void;
  leaveStore?: boolean;
  outOfProximity?: boolean;
}

const UnlockAnimation: React.FC<UnlockAnimationProps> = (props) => {
  const {
    showRedCircle,
    setShowRedCircle,
    setIsButtonClicked,
    isButtonClicked,
    leaveStore,
    outOfProximity,
  } = props;

  const {
    shop,
    letUserInShop,
    openDoor,
    shopData,
    openDoorData,
    setLogOpenDoor,
    shopMessages,
    canUserEnterTheShop,
    getGeolocation
  } = useContext(ShopContext);

  const { setErrorId } = useContext(NewErrorContext);

  const [isTimer, setIsTimer] = useState(true);

  const [resetSlider, setResetSlider] = useState(false);

  const [countdownKey, setCountdownKey] = useState(0);

  const doorWaitTime = shop?.doorWaitTimeInMs / 1000 ?? 6;

  const doorOpenTime = shop?.doorOpenTimeInMs / 1000 ?? 3;

  let history = useHistory();

  // todo: refactor!!!
  useEffect(() => {
    if (resetSlider) {
      setTimeout(() => {
        setResetSlider(false);
        setIsTimer(false);
        setShowRedCircle(false);
        setIsButtonClicked(false);
      }, shop.doorWaitTimeInMs);
    }
  }, [resetSlider]);

  const redirectHandler = () => {
    setIsTimer(true);
    setTimeout(() => {
      history.push("/shop");
    }, shop.doorOpenTimeInMs);
  };


  const handleOpenDoor = () => {
    let openDoorIdAndDirection = {
      id: shopData.shopId,
      direction: { direction: leaveStore ? "EXIT" : "ENTRY" },
    };
    openDoor(openDoorIdAndDirection).then((response: any) => {
      if (openDoorIdAndDirection.direction.direction === "EXIT") {
        LogEvents.logEvent(InfoMessageId.open_door_exit, "Exit page: Open door for exit triggered");
        setIsTimer(true);
      } else if (response?.data?.status === "OPEN") {
        if (shop?.locationCheckRequired && !canUserEnterTheShop()) {
          setIsButtonClicked(true);
          setShowRedCircle(true);
          setResetSlider(true);
          return true;
        }
        setIsButtonClicked(true);
        letUserInShop();
        setLogOpenDoor(true);
        redirectHandler();
        return;
      } else if (
        response?.data?.status === "MAINTENANCE" ||
        response?.data?.status === "CLOSED"
      ) {
        setIsButtonClicked(true);
        setErrorId(ErrorMessagesId.shop_is_closed);
      } else if (response?.data?.status === "FULL") {
        LogEvents.logEvent(InfoMessageId.open_door_triggered_shop_full, `Shop ${shopData.shopId} ${shop.shopName} Full`);
        setIsButtonClicked(true);
        setShowRedCircle(true);
        setResetSlider(true);
      }
    }).catch((exception: any) => {
      setErrorId(ErrorMessagesId.connectivity_issue);
      setIsButtonClicked(false);
      setResetSlider(true);
    })
  }

  const clickedButtonHandler = () => {
    if (!shop?.locationCheckRequired) {
      handleOpenDoor();
      return
    }
    
    getGeolocation(() => {
      handleOpenDoor();
    }, () => {
      setErrorId(ErrorMessagesId.no_access_to_location);
    });
  };

  useEffect(() => {
    setIsTimer(false);
  }, [shopData.shopId]);

  const timerProps = {
    isPlaying: isTimer,
    size: 272,
    strokeWidth: 11,
    trailColor: `${shop?.shopCustomCss?.doorColor}`,
  };

  const timerRedProps = {
    isPlaying: isTimer,
    size: 272,
    strokeWidth: 11,
    trailColor: `${shop?.shopCustomCss?.red}`,
  };

  const renderMessage = () => {
    if (isButtonClicked) {
      if (leaveStore) {
        if (showRedCircle && outOfProximity) {
          return (
            <div className="timer">
              <div className="circle_text--clicked">
                {shopMessages?.LEAVE_STORE_PAGE?.ALREADY_OUTSIDE}
              </div>
            </div>
          );
        }
        return (
          <div className="timer">
            <div className="circle_text--clicked">
              {shopMessages?.LEAVE_STORE_PAGE?.GOODBYE}
            </div>
          </div>
        );
      }
      if (showRedCircle && outOfProximity) {
        return (
          <div className="timer">
            <div className="circle_text--clicked">
              {shopMessages?.UNLOCK_STORE_PAGE?.WAITING}
            </div>
          </div>
        );
      }
      if (
        (openDoorData.status === "CLOSED" ||
          openDoorData.status === "MAINTENANCE" ||
          openDoorData.status === "FULL") &&
        showRedCircle
      ) {
        return (
          <div className="timer">
            <div className="circle_text--clicked">
              {shopMessages?.UNLOCK_STORE_PAGE?.WAITING}
            </div>
          </div>
        );
      }
      return (
        <div className="timer">
          <div className="circle_text--clicked">
            {shopMessages?.UNLOCK_STORE_PAGE?.ENTER}
          </div>
        </div>
      );
    }
    return (
      <div className="timer">
        <div className="circle_text">
          {shopMessages?.UNLOCK_STORE_PAGE?.OPEN_YOURSELF}
        </div>
      </div>
    );
  };

  return (
    <div className="unlock_animation">
      {!showRedCircle && (
        <div className="unlock_animation__circle">
          <CountdownCircleTimer
            {...timerProps}
            duration={doorOpenTime || 3}
            key={countdownKey}
            colors={[
              [`${shop?.shopCustomCss?.doorColor || "#157946"}`, 0.33],
              [`${shop?.shopCustomCss?.doorColorLuminous || "#0ceb79"}`, 0.33],
            ]}
            onComplete={() => {
              setIsTimer(false);
              setCountdownKey(prevKey => prevKey + 1);
            }}
          >
            {renderMessage}
          </CountdownCircleTimer>
        </div>
      )}
      {showRedCircle && (
        <div className="unlock_animation__circle">
          <CountdownCircleTimer
            {...timerRedProps}
            duration={doorWaitTime || 3}
            key={countdownKey + 1}
            colors={[[`${shop?.shopCustomCss?.red || "#ed1c24"}`, 0.33]]}
            onComplete={() => {
              setIsTimer(false);
              setCountdownKey(prevKey => prevKey + 1);
            }}
          >
            {renderMessage}
          </CountdownCircleTimer>
        </div>
      )}
      <div className="unlock_animation__circle_content">
        <div className="slide_button_container">
          <SlideButton
            shootReset={resetSlider}
            onSuccess={() => clickedButtonHandler()}
            onFail={showRedCircle}
          />
        </div>
        <div className="unlock_animation__lock_icon">
          {isButtonClicked && !showRedCircle && (
            <FaLockOpen size="32" color={`${shop?.shopCustomCss?.white}`} />
          )}
          {isButtonClicked && showRedCircle && (
            <FaLock size="32" color={`${shop?.shopCustomCss?.white}`} />
          )}
          {!isButtonClicked && (
            <FaLock size="32" color={`${shop?.shopCustomCss?.white}`} />
          )}
        </div>
      </div>
    </div>
  );
};

export default UnlockAnimation;
