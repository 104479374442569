export interface ICredentials {
  username: string;
  password: string;
}

export interface IConfig {
  backendUrl: string;
  backendAuth: ICredentials;
  paymentUrl: string;
  paymentTokenizationSuccessUrl: string;
  paymentTokenizationFailedUrl: string;
  paymentSuccessUrls: string;
  paymentFailureUrls: string;
  scanditKey: string;
  engineLocation: string;
  appVersion: string;
}

const config: IConfig = {
  backendUrl: process.env.REACT_APP_BACKEND_URL ?? "",
  backendAuth: {
    username:  process.env.REACT_APP_BACKEND_AUTH_USERNAME ?? "",
    password:  process.env.REACT_APP_BACKEND_AUTH_PASSWORD ?? "",
  },
  paymentUrl: process.env.REACT_APP_PAYMENT_URL ?? "",
  paymentTokenizationSuccessUrl: process.env.REACT_APP_PAYMENT_TOKENIZATION_SUCCESS_URL ?? "",
  paymentTokenizationFailedUrl: process.env.REACT_APP_PAYMENT_TOKENIZATION_FAILED_URL ?? "",
  paymentSuccessUrls: process.env.REACT_APP_PAYMENT_SUCCESS_URL ?? "",
  paymentFailureUrls: process.env.REACT_APP_FAILURE_URL ?? "",
  scanditKey: process.env.REACT_APP_SCANDIT_KEY ?? "",
  engineLocation: process.env.REACT_APP_ENGINE_LOCATION ?? "",
  appVersion: process.env.REACT_APP_VERSION ?? "",
}

export default config;
