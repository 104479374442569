import React, { FC } from "react";

interface PopUpTemplateProps {
  children: React.ReactNode;
  showPopup: boolean;
}

const PopUpTemplate: FC<PopUpTemplateProps> = (props) => {
  const { showPopup } = props;

  return (
    <React.Fragment>
      {showPopup && <div className="popup_template">{props.children}</div>}
    </React.Fragment>
  );
};

export default PopUpTemplate;
