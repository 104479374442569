import React, {FC, useContext, useEffect} from "react";
import { useTranslation } from "react-i18next";

import {ExpandableButton, QrScannerComp} from "../../components";
import LogEvents from "../../services/logEvents";

import { ShopContext } from "../../context";
import whiteEnvelope from "../../assets/icons/mail_white.png";
import {useHistory, useLocation} from "react-router-dom";
import {InfoMessageId} from "../../context/types";

function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
}

const Landing: FC = () => {
  const { setShopData, exitShop, shopAvailableLanguages } = useContext(ShopContext);
  const { i18n, t } = useTranslation();

    const history = useHistory();
    const query = useQuery();

  useEffect(() => {
      const languagePassedViaQueryParams = query.get('lang');
      const shopData = JSON.parse(localStorage.getItem('shopData')!);
      let languageCode = languagePassedViaQueryParams ? languagePassedViaQueryParams : shopData?.currentLanguage;

      if (languageCode) {
          i18n.changeLanguage(languageCode.toUpperCase());
          return;
      }

      // format "en-US"
      const browserLocale =
          navigator.languages && navigator.languages.length
              ? navigator.languages[0]
              : navigator.language;

    languageCode = browserLocale.split('-')[0];
    setShopData({ shopId: "0", currentLanguage: languageCode });
    const data = {
      shopId: "0",
      currentLanguage: languageCode,
    };
    localStorage.setItem("shopData", JSON.stringify(data));

    if (doesLanguageBelongToShopLanguages(languageCode)) {
        i18n.changeLanguage(languageCode.toUpperCase());
        return;
    }
    i18n.changeLanguage("EN");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    exitShop();
    if(localStorage.getItem("sessionId")) {
      // LogEvents.logEvents("fallbackPage", "Fallback page (QR Tag scanner) opened");
      LogEvents.logEvent(InfoMessageId.fallback_page_opened, "Fallback page (QR Tag scanner) opened");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localStorage.getItem("sessionId")]);

  const redirectUserToSupportPage = async() => {
      await history.push("/support-form");
  }

    const doesLanguageBelongToShopLanguages = (languageCode: string): boolean => {
        return shopAvailableLanguages.find(language => language.code.toLowerCase() === languageCode.toLowerCase()) !== undefined;
    }

  return (
    <div className="landing">
      <div className="landing__dropdown_button">
        <ExpandableButton type="green" invalidShopID />
      </div>
      <div className="landing__text_container">
        <p className="landing__title">{t("LANDING_PAGE.TITLE")}</p>
        <p className="landing__text">{t("LANDING_PAGE.TEXT")}</p>
      </div>
      <div className="landing__scanner">
        <QrScannerComp />
      </div>
        <div className="landing__text">
            <div className='landing__text_flex' onClick={redirectUserToSupportPage}>
                <img
                    alt="left"
                    src={whiteEnvelope}
                />
                {t('SUPPORT.ACTION_TEXT')}
            </div>
        </div>
    </div>
  );
};

export default Landing;
