import React, {createContext, useContext} from "react";

import { AxiosContext } from "./AxiosContext";
import {
    CreatePaymentTokenData,
    CreateTokenizationUrlData,
    InfoMessageId
} from "./types";
import LogEvents from "../services/logEvents";

interface IPaymentTokenizationContext {
    createTokenizationUrl: (data: CreateTokenizationUrlData) => any;
    createToken: (data: any) => any;
}

const PaymentTokenizationContext = createContext({} as IPaymentTokenizationContext);

interface IPaymentTokenizationContextProps {
    children: React.ReactNode
}

const PaymentTokenizationContextProvider: React.FC<IPaymentTokenizationContextProps> = (props) => {
    const { axiosInstance, setPaymentLinkClicked } = useContext(AxiosContext);

    const createTokenizationUrl = (data: CreateTokenizationUrlData) => {
        return axiosInstance({
            method: "POST",
            url: `/create-tokenization-url/`,
            data: data
        }).then((response: any) => {
            LogEvents.logEvent(InfoMessageId.payment_token_initialed, "Tokenization initiated");
            setPaymentLinkClicked(false);
            return response.data;
        }).catch((error: any) => {
            LogEvents.logEvent(InfoMessageId.payment_token_failed, "Tokenization failed");
            throw error;
        })
    }

    const createToken = (data: CreatePaymentTokenData) => {
        return axiosInstance({
            method: "POST",
            url: `/create-token/`,
            data: data
        }).then((response: any) => {
            return response.data;
        }).catch((error: any) => {       
            LogEvents.logEvent(InfoMessageId.payment_token_failed, "Tokenization failed");
            throw error;
        })
    };

    const providerValue = {
        createTokenizationUrl,
        createToken
    }

    return (
        <PaymentTokenizationContext.Provider value={providerValue}>
            {props.children}
        </PaymentTokenizationContext.Provider>
    )
}

export { PaymentTokenizationContextProvider, PaymentTokenizationContext };