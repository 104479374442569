import React, { createContext, useState } from "react";
interface IUserContext {
  guestUser: SessionUser;
  setGuestUser(userData: SessionUser): void;
  authUser: any;
}

const UserContext = createContext({} as IUserContext);

interface IUserContextProps {
  children: React.ReactNode;
}

const UserContextProvider: React.FC<IUserContextProps> = (props) => {
  const [guestUser, setGuestUser] = useState<SessionUser>({} as SessionUser);

  const guestUserLocal = JSON.parse(localStorage.getItem("guestUser")!);

  const isThereUser = () => {
    if (guestUserLocal) {
      return !guestUserLocal.shopId ? false : true;
    }
  };

  const authUser = {
    isAuthenticated: isThereUser(), //default false
    login(callback: any) {
      authUser.isAuthenticated = true;
      setTimeout(callback, 100); // fake async
    },
    logout(callback: any) {
      authUser.isAuthenticated = false;
      setTimeout(callback, 100);
    },
  };

  const providerValue = {
    guestUser,
    setGuestUser,
    authUser,
  };

  return (
    <UserContext.Provider value={providerValue}>
      {props.children}
    </UserContext.Provider>
  );
};

export { UserContext, UserContextProvider };
